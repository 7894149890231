// Material React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Material React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {white} = colors;
const {borderRadius} = borders;

export default {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: white.main,
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};
