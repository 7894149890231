import React, {useEffect, useState} from "react"
import {Grid, TextField, Typography} from "@mui/material";
import Container from "./Container";
import Cookies from 'universal-cookie'
import PropTypes from "prop-types";
import MDTypography from "../components/MDTypography";
import Icon from "@mui/material/Icon";

function PassGate(props) {

  const [valid, setValid] = useState(false)

  const cookies = new Cookies();

  useEffect(() => {
    setValid(false)
    let c = cookies.get(props.gate, {path: '/'})
    c && setValid(true)
    !props.pass && setValid(true)
  }, [props.gate])

  const evalPw = (e) => {
    e.target.value === props.pass && setValid(true);
    e.target.value === props.pass && cookies.set(props.gate, true, {
      path: '/',
      expires: new Date(Date.now() + 31541845719)
    })
  }

  return (
    <>
      {valid ?
        props.children :
        <Container>
          <MDTypography variant="h5">{props.title}</MDTypography>
          <Grid item xs={12} style={{textAlign: 'center', marginTop: 50}}>
            <Icon fontSize="large" style={{marginRight: 10, paddingTop: 3}}>lock</Icon>
            {/*<Typography variant="h6" style={{marginBottom: '20px'}} autoComplete="new-password">Enter*/}
            {/*  password to log in:</Typography>*/}
            <TextField label="Password" type="password" onChange={evalPw}/>
          </Grid>
        </Container>
      }

    </>
  )
}

PassGate.propTypes = {
  children: PropTypes.any,
  pass: PropTypes.any,
  gate: PropTypes.any,
  title: PropTypes.any
};

export default PassGate;
