import React from "react";
import Button from "@mui/material/Button";
import Api from "../../../Api/Api";
import styles from "../profile/Certificates/styles";
import language from "../../../assets/data/language";
import dayjs from "dayjs";
import { renderToStaticMarkup } from "react-dom/server"
import emailjs from "emailjs-com";

const NotifyUsers = ({}) => {
  const spanish = false;
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const emailUsers = async () => {
    setSending(true)
    const emails = [];
    let success = 0;
    Api.certificatesStatus().then(res => {
      console.log(res, "RES");
      if(res.data) {
        const users = Object.keys(res.data);
        success = users.length;
        console.log(users, "USRSDS")
        for(let user in users) {
          const userId = users[user];
          const {pending, expiring, completed, expired, userJC, name, email} = res.data[userId];
          // console.log(pending.length, expiring.length, completed.length, expired.length, "LEN")
          // console.log( res.data[userId], "USER")
          const html_div = <div>
            <div style={styles.notification.section}>
              <h3 style={styles.notification.tableHead}>{language.certificates.expired(spanish)}</h3>
              <ul>
                {expired.map((cert, i) => <li key={i}>{cert.title}: expired on {dayjs(cert.expiration).format('MM/DD/YYYY')}</li>)}
              </ul>
            </div>
            <div style={styles.notification.section}>
              <h3 style={styles.notification.tableHead}>{language.certificates.expiring(spanish)}</h3>
              <ul>
                {expiring.map((cert, i) => <li key={i}>{cert.title}: expiring {dayjs(cert.expiration).format('MM/DD/YYYY')}</li>)}
              </ul>
            </div>
            <div style={styles.notification.section}>
              <h3 style={styles.notification.tableHead}>{language.certificates.completed(spanish)}</h3>
              <ul>
                {completed.map((cert, i) => <li key={i}>{cert.title}</li>)}
              </ul>
            </div>
            <div style={styles.notification.section}>
              <h3 style={styles.notification.tableHead}>{language.certificates.pending(spanish)}</h3>
              <ul>
                {pending.map((cert, i) => <li key={i}>{cert.title}</li>)}
              </ul>
            </div>
          </div>;
          let data = {
            service_id: 'service_leemyzb',
            template_id: "template_ef9zlre",
            user_id: 'OEpIsDjbJHxouxTll',
            template_params: {
              to_name: name,
              job_classification: userJC,
              certification_update: renderToStaticMarkup(html_div)
            }
          };
          // const html_string = renderToStaticMarkup(html_div)
          // emailjs.send(data.service_id, data.template_id, data.template_params, data.user_id).then((result) => {
          //     success += 1;
          //   }, (error) => {
          //     console.log(error)
          //   });

        }
      }
      setSending(false)
      setSent(`SENT TO ${success} USERS`);
      setTimeout(() => setSent(false),8000)
    }).catch(err => console.log(err));
  }

  return <div style={{textAlign: 'center'}}>
    {sent && <p style={{marginBottom: 10}}>{sent}</p>}
    <Button variant="contained" onClick={emailUsers}>{sending ? "....PROCESSING" : "Notify Users of Certifications"}</Button>

  </div>
}


export default NotifyUsers;
