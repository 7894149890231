// Material React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

// Material React base styles
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

const {transparent} = colors;
const {lg} = boxShadows;
const {borderRadius} = borders;

export default {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: lg,
      padding: pxToRem(8),
      borderRadius: borderRadius.md,
    },
  },
};
