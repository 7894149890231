import {useEffect, useRef, useState} from "react";

// react-router components
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material example components
import Sidenav from "components/Sidenav";
// import Configurator from "components/Configurator";
import ErrorBoundary from "components/ErrorBoundary";

// Material  React themes
import theme from "assets/theme";

// Material React Dark Mode themes
// Material React routes
import routes from "routes";

// Material React contexts
import {setMiniSidenav, useMaterialUIController} from "context";
import {resetActiveQuiz, useUserController} from "context/user";
import Api from "./Api/Api";
import CardView from "./oldComponents/CardView";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const [userController, userDispatch] = useUserController();
  const {user} = userController;
  const fetchRef = useRef(false);

  const {miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode} =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [videos, setVideos] = useState(false);
  const location = useLocation();
  const history = useNavigate();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location.pathname]);

  const getVideos = () => {
    Api.getAllCats().then(data => {
      setVideos(data.data);
      fetchRef.current = true;
    }).catch(err => setVideos([]))
  }
  if (!videos && !fetchRef.current) getVideos()

  const buildVideos = () => {
    let links = []
    videos.forEach((c, i) => {
      let slug = c.title.toLowerCase();
      slug = slug.replace(/\s+/g, '');
      if (slug !== "") links.push(
        <Route exact key={i} path={`/videos/${slug}`} element={<CardView/>}></Route>
      )
    })

    return links;
  }
  const getRoutes = (allRoutes) => {
    const routesArr = allRoutes.map((route) => {

      if (route.user === "Admin" && user && !user.Admin) {
        return null;
      }


      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key}/>;
      }

      return null;
    });


    return routesArr;
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>

      {layout === "dashboard" && userController.user && (
        <>

          <ErrorBoundary user={userController.user} history={history}>

            {}
            <Sidenav
              color={sidenavColor}
              brandName="Marathon Electric"
              routes={
                routes.filter((route) => (
                    route.user === "Admin"
                      ? (userController.user && userController.user.Admin)
                      :

                      true
                  )
                )
              }


              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              // instance={instance}
            />


          </ErrorBoundary>
        </>
      )}
      <ErrorBoundary user={userController.user} history={history}>
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Navigate to="/videos"/>}/>
          <Route path="/auth" element={<Navigate to="/auth"/>}/>
          {videos && buildVideos()}

        </Routes>

      </ErrorBoundary>
    </ThemeProvider>
  );
}

