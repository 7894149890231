import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import language from "../../../../assets/data/language";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import FileInputButton from "../../../../components/FileInputButton";
import Modal from "@mui/material/Modal";
import React from "react";
import PropTypes from "prop-types";

const UploadModal = (
  {
    certUploader,
    closeModal,
    spanish,
    handleUploadQuiz,
    quizzes,
    otherUploadQuiz,
    otherUploadQuizSource,
    setOtherUploadQuizSource,
    fileMessage,
    uploadCert,
    uploadQuiz,
    uploadIssued,
    uploadExpiration,
    setUploadExpiration,
    setUploadIssued
  }) => {
  const categoryQuizzes = {
    "ThreeYears": [],
    "TwoYears": [],
    "Annual": [],
    "Other": []
  };

  // const categoryNull = Object.keys(categoryQuizzes).length === 0;
  if(quizzes) quizzes.forEach((quiz) => {
    let freq = quiz.quizFrequency;
    if(freq === "") freq = "Other";
    if(categoryQuizzes[freq]) categoryQuizzes[freq].push(quiz);
    else categoryQuizzes[freq] = [quiz]
  });


  return <Modal
    open={certUploader}
    onClose={closeModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <div style={{backgroundColor: 'white', width: 300, padding: 20, borderRadius: 10}}>
      <Grid item xs={12}>
        <InputLabel style={{marginBottom: 10}}>{language.certificates.sourceQuiz(spanish)}</InputLabel>
        {Object.keys(categoryQuizzes).length !== 0 && Object.keys(categoryQuizzes).map((cat, i) => {
          let disabled = false

          if(uploadQuiz !== "") {
            const quizPresent = categoryQuizzes[cat].find(q => q.id === uploadQuiz);
            if(!quizPresent) disabled = true;
          } else if (otherUploadQuiz && cat !== "Other") disabled = true;
          return <FormControl key={i} fullWidth style={{marginBottom: 10}}>
            <InputLabel>{language.certificates[cat](spanish)}</InputLabel>
            <Select
              label={language.certificates.sourceQuiz(spanish)}
              value={uploadQuiz}
              disabled={disabled}
              onChange={e => handleUploadQuiz(e)}
            >
              {(uploadQuiz !== "" || otherUploadQuiz) && <MenuItem key="clear" value="clear" style={{color: '#ff6b6b'}}>{spanish ? "Deseleccionar" : "Clear"}</MenuItem>}
              {categoryQuizzes[cat] && categoryQuizzes[cat].map((cls, i) => <MenuItem key={i}
                                                                                      value={cls.id}>{spanish ? cls.spanish.title || cls.english.title : cls.english.title}</MenuItem>)}
              {cat === "Other" && <MenuItem key="other" value="Other">{spanish ? "Otro" : "Other"}</MenuItem>}


            </Select>
          </FormControl>
        })}
        {otherUploadQuiz && <>
          <FormControl fullWidth style={{marginBottom: 20}}>
            <TextField
              fullWidth
              color="secondary"
              label={language.certificates.otherSourceQuiz(spanish)}
              value={otherUploadQuizSource || ""} onChange={(e) => setOtherUploadQuizSource(e.target.value)}/>
          </FormControl>
          <Grid item xs={12}>
            <InputLabel style={{marginBottom: 5}}>{language.certificates.expiration(spanish)}</InputLabel>
            <FormControl fullWidth style={{marginBottom: 5}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker  disableFuture={false} disablePast={true} onChange={(e) => setUploadExpiration(e)} value={dayjs(uploadExpiration)}/>
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </>}
        <Grid item xs={12}>
          <InputLabel style={{marginBottom: 5}}>{language.certificates.issuedDate(spanish)}</InputLabel>
          <FormControl fullWidth style={{marginBottom: 5}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker  disableFuture={true} onChange={(e) => setUploadIssued(e)} value={dayjs(uploadIssued)}/>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        {((uploadQuiz || otherUploadQuizSource) && uploadIssued) && <Grid item xs={8}>
          <FileInputButton onChange={uploadCert} name={language.certificates.addFile(spanish)}/>
        </Grid>}
        {fileMessage && <p>{fileMessage}</p>}
      </Grid>
    </div>
  </Modal>
}

UploadModal.propTypes = {
  certUploader: PropTypes.any,
  closeModal: PropTypes.any,
  spanish: PropTypes.any,
  handleUploadQuiz: PropTypes.any,
  quizzes: PropTypes.any,
  otherUploadQuiz: PropTypes.any,
  otherUploadQuizSource: PropTypes.any,
  setOtherUploadQuizSource: PropTypes.any,
  fileMessage: PropTypes.any,
  uploadCert: PropTypes.any,
  uploadQuiz: PropTypes.any,
  uploadIssued: PropTypes.any,
  uploadExpiration: PropTypes.any,
  setUploadExpiration: PropTypes.any,
  setUploadIssued: PropTypes.any,
}

export default UploadModal;
