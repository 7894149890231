import Card from "@mui/material/Card";
import "flatpickr/dist/flatpickr.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import {useUserController} from "context/user";
import {Link} from "react-router-dom";
import {useMaterialUIController} from "../../../context";
import styles from './styles';
import {useDevice} from "../../../context/device";
import Api from "../../../Api/Api";
import React, {useRef, useState} from "react";
import trainingMatrix from "../../../assets/images/trainingMatrix.png"

function Home() {
  const [videos, setVideos] = useState(false);
  const fetchRef = useRef(false);
  const [userController] = useUserController();
  const {user} = userController;
  const [controller, dispatch] = useMaterialUIController();
  const device = useDevice();
  const {spanish} = controller;

  // const videos = routes.filter(route => route.video);

  const getVideos = () => {
    Api.getAllCats().then(data => {
      setVideos(data.data.reverse());
      fetchRef.current = true;
    }).catch(err => {
      console.log(err);
      setVideos([])
    })
  }
  if (!videos && !fetchRef.current) getVideos()

  if (userController.user && videos) {
    return (
      <DashboardLayout>
        <DashboardNavbar/>
        <MDBox py={0}>
          <Card>

            <MDBox p={3} lineHeight={1} height="80vh" style={{overflow: 'scroll'}}>
              <MDTypography
                variant="h5">{spanish ? 'Entrenamiento de Seguridad para Marathon' : 'Marathon Safety Training'}</MDTypography>

              <MDBox pt={2} lineHeight={1}>
                <div style={device == "mobile" ? styles.linkContainerMobile : styles.linkContainer}>

                  {videos && videos.map((video, i) => {
                    let slug = video.title.toLowerCase();
                    slug = slug.replace(/\s+/g, '');
                    if (!video.isDeleted && ((userController.user.Admin && userController.user.Admin === "Admin+") || video.id !== "105f5a00-8896-4171-b1cc-ba786e501265")) return <div
                      style={device === "mobile" ? styles.videoLinkMobile : styles.videoLink} key={i}>
                      <Link to={`/videos/${slug}?id=${video.id}`}
                            style={styles.link}>{spanish && video.spanish ? video.spanish : video.title}</Link>
                    </div>
                  })}
                </div>
                <MDTypography
                  variant="h5">{spanish ? 'Matriz de Entrenamiento' : 'Training Matrix'}</MDTypography>

                <div style={styles.trainingMatrix}>
                  <img src={trainingMatrix} style={{height: '100%'}}/>
                </div>

              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        <Footer/>
      </DashboardLayout>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <DashboardLayout>
        <MDBox py={3}>
          You must be logged in to access this page, redirecting you to the login page...
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default Home;
