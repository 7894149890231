import React, {useEffect, useState} from 'react';
import MultipleChoice from './MultipleChoice';
import TrueFalse from './TrueFalse';

import LongAnswer from './LongAnswer';
import Container from './Container';
import {Box, Grid} from '@mui/material';

import Api from '../Api/Api'
import ResultsModal from './ResultsModal';
import {setActiveQuiz, useUserController} from "../context/user";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import MDBox from "../components/MDBox";
import Card from "@mui/material/Card";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import {useMaterialUIController} from "../context";


function QuizPage() {

  const [quiz, setQuiz] = useState(null)
  const [answers, setAnswers] = useState([])
  const [valid, setValid] = useState(false)
  const [answered, setAnswered] = useState([])
  const [ak, setAk] = useState([])
  const [pv, setPv] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [score, setScore] = useState(0)
  const [isGroupCode, setIsGroupCode] = useState(false)
  const [groupCode, setGroupCode] = useState('')
  const [groupCodeActive, setGroupCodeActive] = useState(false)
  const [fetched, setFetched] = useState(false);

  // CONTEXT
  const [controller] = useMaterialUIController();
  const {spanish} = controller;
  const [userController, userDispatch] = useUserController();
  const {user, activeQuiz} = userController;
  const [activeQuizState, setActiveQuizState] = useState(activeQuiz);


  // REFS
  const quizStartTime = React.useRef(new Date());

  useEffect(() => {
    handleGCID();
  }, [])

  useEffect(() => {
    setActiveQuiz(userDispatch, {
      quizStartTime: quizStartTime.current,
    })
    setActiveQuizState({
      ...activeQuizState,
      quizStartTime: quizStartTime.current,
    })
  }, [quizStartTime.current])

  const handleGCID = async () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    const gcid = urlParams.get('gcid')
    gcid && setIsGroupCode(true)
    gcid && setGroupCode(gcid)

    let updatedQuiz = {}
    let questions = []
    if (gcid !== '') {
      Api.getGroupCode(gcid).then((quiz) => {
        if (quiz.data[0]) {
          // console.log(quiz.data[0], "))))))))")
          setActiveQuiz(userDispatch, {
            id: quiz.data[0].id,
            quizStartTime: new Date(),
            isGroupCode: true,
            videoName: quiz.data[0]?.english?.title || ''
          })
          setActiveQuizState({
            ...activeQuizState,
            id: quiz.data[0].id,
            quizStartTime: new Date(),
            isGroupCode: true,
            videoName: quiz.data[0]?.english?.title || ''
          })
          setGroupCodeActive(quiz.data[0].groupActive)
          updatedQuiz = quiz.data[0]
          quiz.data[0].questions.forEach(q => {
            !q.isDeleted && questions.push(q)
          })
          updatedQuiz.questions = questions
          return updatedQuiz
        } else {
          return false
        }
      }).then(quiz => {
        if (quiz) {
          quiz.groupActive && setQuiz(quiz)
          return quiz.groupActive ? quiz : false
        } else {
          return false
        }
      }).then(q => {
        q !== false && buildAnswerKey(q);
        return true
      }).then(
        setFetched(true)
      ).catch((err) => {
        setFetched(true)
      })
    }
  }

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    const gcid = urlParams.get('gcid')
    if (!loaded) {
      if (!gcid) {
        // setQid(user.qid)
        fetchQuiz(activeQuiz.id)
        // user.qid = ''
        setLoaded(true)
      }
    }
  }, [loaded])

  const fetchQuiz = (id) => {
    let updatedQuiz = {}
    let questions = []
    Api.getOneQuiz(id).then(quiz => {
      updatedQuiz = quiz.data[0]
      quiz.data[0].questions.forEach(q => {
        !q.isDeleted && questions.push(q)
      })
      updatedQuiz.questions = questions
      return updatedQuiz
    }).then(quiz => {
      setQuiz(quiz)
      return quiz
    }).then(q => {
      buildAnswerKey(q);
      return true
    }).then(
      setFetched(true)
    ).catch((err) => {
      setFetched(true)
    })
  }

  const buildQuiz = (quiz) => {
    let questions = quiz.questions;
    let quizDisplay = [];
    questions.forEach((q, i) => {
      switch (q.type) {
        case 'mc':
          quizDisplay.push(<MultipleChoice key={q.id} q={spanish ? q.spanish : q.english} qid={q.id}
                                           count={i} cb={setTheAnswers}/>)
          break;
        case 'tf':
          quizDisplay.push(<TrueFalse key={q.id} span={spanish} q={spanish ? q.spanish : q.english}
                                      qid={q.id} count={i} cb={setTheAnswers}/>)
          break;
        case 'long':
          quizDisplay.push(<LongAnswer q={spanish ? q.spanish : q.english} key={q.id} cb={setTheAnswers}/>)
          break;
        default:
          break;
      }
    })

    return (<ul style={{listStyleType: 'decimal'}}>{quizDisplay}</ul>)
  }

  const buildAnswerKey = (q) => {
    let questions = q.questions;
    let answers = [];
    let pointvalues = [];
    questions.forEach(q => {
      if (!q.isDeleted) {
        answers.push(q.answer);
        pointvalues.push(1);
      }
    })

    setAk(answers)
    setPv(pointvalues)
  }

  const setTheAnswers = (a) => {
    let ans = answers;
    let ansd = answered;
    ans[a.q] = a.a;
    ansd[a.q] = true;
    setAnswers(ans)
    setAnswered(ansd)

    validateQuiz(ak);
  }

  const validateQuiz = (a) => {
    let valid = false
    let ansd = answered;
    for (let i = 0; i < a.length; i++) {
      if (ansd[i] !== true) {
        valid = false;
        break;
      } else {
        valid = true;
      }
    }

    valid === true && setValid(true)
  }

  const evalQuiz = async () => {
    let fa = answers;
    let qa = ak;
    let pval = pv;
    let total = 0;
    let possible = 0;
    let score = 0;


    for (let i = 0; i < fa.length; i++) {
      let points = parseInt(pval[i]);
      possible += parseInt(pval[i]);
      if (fa[i] == qa[i]) {
        total += points;
      }

    }

    score = (total / possible) * 100

    setScore(score)
    await updateData(score)

    return score
  }

  const updateData = async (s) => {
    console.log(s, "S")
    setActiveQuiz(userDispatch, {
      score: s,
      quizStartTime: quizStartTime.current,
      quizEndTime: new Date()
    })
    setActiveQuizState({
      ...activeQuizState,
      score: s,
      quizStartTime: quizStartTime.current,
      quizEndTime: new Date()
    })
    // console.log(activeQuiz, activeQuizState, 'user?')

  }

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox py={0}>
        <Card>
          <div style={{
            padding: '20px 60px',
            overflow: 'scroll'
          }}>
            {quiz ? fetched &&
              <Container title={`Quiz: ` + (spanish ? quiz.spanish.title : quiz.english.title)}>
                <Grid container>
                  {buildQuiz(quiz)}
                </Grid>
                <Box>
                  {isGroupCode && !groupCodeActive ? '' :
                    <ResultsModal spanish={spanish} user={user} cb={evalQuiz} dis={!valid} data={quiz} score={score ? score : 0}/>
                  }
                </Box>
              </Container>
              :
              <Container title={groupCode ? 'Invalid Group Code.' : 'No Quiz Found.'}>
              </Container>
            }
          </div>
        </Card>
      </MDBox>
    </DashboardLayout>

  )

}

export default React.memo(QuizPage)
