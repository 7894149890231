import React, {Component} from "react"
import {Button, Grid, Typography} from "@mui/material";
import SingleCat from "./SingleCat";
import {v4} from 'uuid';
import Api from '../../../Api/Api';

class CatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cats: [],
      quizzes: false
    }
  }

  componentDidMount() {
    this.fetchCats();
    Api.getAllQuizzes().then(data => {
      this.setState({quizzes: data.data})
    })
  }

  fetchCats = () => {
    Api.getAllCats().then(data => {
      this.setState({cats: data.data});
    })
  }

  displayCats() {
    var cats = this.state.cats;
    var output = []

    cats.forEach((c, i) => {
      if (c.isDeleted !== true) {
        output.push(
          <SingleCat key={i} data={{
            id: c.id,
            title: c.title,
            edit: true,
            spanish: c.spanish,
            isDeleted: c.isDeleted || false,
            quizzes: this.state.quizzes,
            quiz: c.quiz,
            password: c.password
          }} new={c.title ? false : true} del={() => {
            this.delCat(c.id)
          }}/>
        )
      }

    })
    return output;
  }

  newCat = () => {
    var cats = this.state.cats;
    var cid = v4();
    cats.push({id: cid, title: ''})
    this.setState({cats: cats})
  }

  delCat = (id) => {
    var cats = this.state.cats
    cats.forEach(c => {
      c.isDeleted = c.id === id && true
    })
    this.setState({cats: cats})
  }

  render() {
    return (
      <>
        <Grid container style={{marginBottom: '10px'}}>
          <Grid item xs={6} display="flex" alignItems="center">
            <Typography variant="h5" style={{padding: '12px 0'}}>Categories</Typography>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', justifyContent: 'right'}} display="flex"
                alignItems="center">
            <Button variant="outlined" onClick={this.newCat}>New Category</Button>
          </Grid>
        </Grid>
        <Grid container
              style={{marginTop: '10px', borderTop: '1px solid #ccc', maxHeight: "400px", overflow: "scroll"}}>
          <Grid item xs={12}>
            {this.state.quizzes && this.displayCats()}
          </Grid>
        </Grid>
      </>
    )
  }
}

export default CatList;
