export default {
  // UC4C: { primary: "#381440", secondary: "#f68a30", light: "#9c8aa0" },
  // AFC: { primary: "#3E434A", secondary: "#E61D30", light: "#455775" },
  MARATHON: {primary: "#D43D32", secondary: "#f0f2f5"},
  Theme: {
    white: '#fff',
    offWhite: '#f9f9f9',
    shade: '#f9f9f9',
    lightGray: 'rgba(232, 232, 232, 0.6)',
    gray: '#bababc',
    darkGray: '#505153',
    black: '#25262c',
    highlight: '#eef7fe',
    background: '#e5e8ec',
    green: '#0f8048',
    success: '#13a55c',
    successLight: 'rgba(19,165,92,0.71)',
    successAlt: '#16b867',
    lightGreen: 'rgba(19, 165,92, .2)',
    red: 'rgb(230, 39, 18)',
    error: '#E62712',
    errorAlt: '#ed3f3f',
    lightError: 'rgba(230, 39, 18, .1)',
    blue: '#569adb',
    lightBlue: 'rgba(89, 154,219,.1)'
  }
};
