import Card from "@mui/material/Card";
import "flatpickr/dist/flatpickr.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import {useUserController} from "context/user";
import {useMaterialUIController} from "../../../context";
import language from "../../../assets/data/language";
import dayjs from "dayjs";
import azure from "../../../utils/azure";
import React, {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import File from "./file";
import {Button, FormControl, TextField} from "@mui/material";
import FileInputButton from "../../../components/FileInputButton";
import styles from "./styles";

function SafetyDocuments() {
  const [userController] = useUserController();
  const {user} = userController;

  const [controller, dispatch] = useMaterialUIController();
  const {spanish} = controller;

  const {fetchSafetyDocs} = azure;
  const fetchControl = React.useRef(false);

  const [safetyDocuments, setSafetyDocuments] = useState(false);
  const [filteredSafetyDocuments, setFilteredSafetyDocuments] = useState(false);
  const [uploadDoc, setUploadDoc] = useState(false);
  const [categoryUpload, setCategoryUpload] = useState(false);

  const getCertificates = async () => {
    fetchControl.current = true;
    const certificates = await fetchSafetyDocs().then(certs => certs).catch(err => console.log(err));
    setSafetyDocuments(certificates)
    setFilteredSafetyDocuments(certificates)
  }

  if (!safetyDocuments && !fetchControl.current) getCertificates()

  const uploadDocument = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file && ["application/pdf"].includes(file.type)) {
      let file_name = file.name;
      const meta = {
        cat: categoryUpload
      }
      azure.uploadSafetyDocuments(file, `${file_name}`, meta).then(saved => {
        fetchControl.current = false;
        getCertificates();
      }).catch(err => console.log(err));
    }
  }


  const adminType = user.Admin;

  const filterDocs = (e, docs) => {
    console.log(safetyDocuments, safetyDocuments[docs], e.target.value)
    let newDocs = {...safetyDocuments};
    if(e.target.value.length > 0) {
      const fDocs = newDocs[docs].filter(c => {
        // console.log( c.props.data.english.title.toLowerCase(), e.target.value.toLowerCase(), c.props.data.english.title.toLowerCase().includes(e.target.value.toLowerCase()))
        if(c.name.toLowerCase().includes(e.target.value.toLowerCase())) return c
      })
      newDocs[docs] = fDocs
    } else newDocs[docs] = safetyDocuments[docs]
    setFilteredSafetyDocuments(newDocs)
  }

  if (userController.user) {
    return (
      <DashboardLayout>
        <DashboardNavbar/>
        <MDBox py={0}>
          <Card>

            <MDBox p={3} lineHeight={1} height="80vh" style={{overflow: 'scroll'}} >
              <MDTypography variant="h5" style={{marginBottom: 20}}>
                {language.common.safetyDocuments(spanish)}
                {(user.Admin && (user.Admin === "Admin" || user.Admin === "Admin+")) && <Button
                  variant={"contained"}
                  style={{padding: 5, marginLeft: 10, fontSize: ".7rem"}}
                  onClick={() => setUploadDoc(!uploadDoc)}
                >{uploadDoc ? "Cancel Upload" : "Upload"}</Button>}
              </MDTypography>
              {uploadDoc && <p style={styles.message}>Only PDFs are allowed</p>}
              {uploadDoc && <MDBox style={styles.uploadContainer}>
                <FormControl>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Category"
                    value={categoryUpload || ""} onChange={(e) => setCategoryUpload(e.target.value)}/>
                </FormControl>
                {categoryUpload && <FileInputButton onChange={uploadDocument} name={language.certificates.addFile(spanish)}/>}
              </MDBox>}
              {/*<MDTypography variant="h6">You Role: {user.}</MDTypography>*/}
              {filteredSafetyDocuments && Object.keys(filteredSafetyDocuments).map((fileName) => {
                  if (filteredSafetyDocuments[fileName].length !== 0) {
                    return (
                      <MDBox py={1.2} key={fileName}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <MDTypography variant="h6" textTransform="capitalize" lineHeight={2}>
                              {fileName}
                            </MDTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <MDBox height="30vh" lineHeight={1} overflow="auto">
                              <input
                                style={{
                                  transition: 'box-shadow 0.2s ease 0s',
                                  padding: 8,
                                  border: '1px solid rgba(0, 22, 66, 0.32)',
                                  background: 'rgb(246, 249, 250)',
                                  outline: 'none',
                                  marginBottom: 20,
                                  borderRadius: 2
                                }}
                                autoComplete="off"
                                placeholder="Search"
                                onChange={e => filterDocs(e, fileName)}
                                // value={filterText}
                              />
                              {filteredSafetyDocuments[fileName] &&
                                filteredSafetyDocuments[fileName].map((file) => {
                                  let returnValue = null;
                                  if (
                                    file &&
                                    !file.name.includes(".bak") &&
                                    !file.name.includes("conflicted")
                                  )
                                    returnValue = <File key={file.name} refresh={getCertificates} file={file} canDelete={(user.Admin && (user.Admin === "Admin" || user.Admin === "Admin+"))}/>;
                                  return returnValue;
                                })}
                            </MDBox>
                          </AccordionDetails>
                        </Accordion>
                      </MDBox>
                    );
                  }
                  return null;
                })}

            </MDBox>
          </Card>
        </MDBox>
        <Footer/>
      </DashboardLayout>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <DashboardLayout>
        <MDBox py={3}>
          You must be logged in to access this page, redirecting you to the login page...
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default SafetyDocuments;
