import React, {Component} from "react"
import {Button, Grid, TextField, Typography} from "@mui/material";
import colors from "../../../colors";
import Api from '../../../Api/Api'
import PropTypes from "prop-types";

// import { v4 as uuidv4 } from 'uuid';

class QuizList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quizzes: [],
      search: ''
    }
    this.adminPlus = this.props.type === "Admin+";
  }

  componentDidMount() {
    this.fetchQuizzes();
  }

  fetchQuizzes = () => {
    Api.getAllQuizzes().then(data => {
      this.setState({quizzes: data.data});
    })
  }

  displayQuizzes() {
    const quizzes = this.state.quizzes;
    const output = [];
    const search = this.state.search || '';
    quizzes.forEach((q, i) => {
      const qlc = q.english.title.toLowerCase();
      const slc = search.toLowerCase();
      if (qlc.includes(slc)) {
        output.push(
          <Grid key={i} container style={{borderBottom: '1px solid #eee', padding: '10px'}}>
            <Grid item xs={8} display="flex" alignItems="center"><Typography>{q.english.title}</Typography></Grid>
            <Grid item xs={4} style={{textAlign: 'right'}}>
              <Button href={"/admin/quizbuilder/?q=" + q.id} style={{color: colors.MARATHON.primary}}>Edit Quiz</Button>
            </Grid>
          </Grid>
        )
      }
    })

    return output;
  }

  search = (e) => {
    const s = e.target.value;
    this.setState({search: s})
  }

  render() {
    return (
      <>
        <Grid container style={{marginBottom: '10px'}}>
          <Grid item xs={3} display="flex" alignItems="center">
            <Typography variant="h5">Quizzes</Typography>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            <TextField fullWidth label="Search" onChange={this.search}/>
          </Grid>
          {this.adminPlus && <Grid item xs={3} style={{justifyContent: 'right'}} display="flex" alignItems="center">
            <Button variant="outlined" href={"/admin/quizbuilder/"} style={{color: colors.MARATHON.primary}}>New
              Quiz</Button>
          </Grid>}
        </Grid>
        <Grid container
              style={{marginTop: '10px', borderTop: '1px solid #ccc', maxHeight: "400px", overflow: "scroll"}}>
          <Grid item xs={12}>
            {this.displayQuizzes()}
          </Grid>
        </Grid>
      </>
    )
  }
}

QuizList.propTypes = {
  type: PropTypes.string.isRequired
}

export default QuizList;
