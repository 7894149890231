export default {
  fileCard: {
    padding: "5px 5px 5px 10px",
    borderRadius: 3,
    display: "flex",
    backgroundColor: "#f5f5f5",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  buttonTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  buttonTagTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttons: {
    marginBottom: 5,
    width: "100%",
    marginTop: 20,
    padding: 15,
    overflow: "scroll",
    height: "92%",
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: 10,
    marginBottom: 20,
    borderBottom: "1px solid #c3c3c3"
  },
  message: {
    fontSize: ".8rem",
    marginBottom: 10
  }
};
