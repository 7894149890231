import colors from '../../../colors';

const container = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 20
}
const videoLink = {
  backgroundColor: colors.Theme.offWhite,
  padding: 20,
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  border: '1px solid rgba(0, 22, 66, 0.32)',
  alignItems: 'center'
}
export default {
  trainingMatrix: {
    width: '100%',
    overflowX: 'scroll',
    height: 600
  },
  linkContainer: {
    ...container,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  linkContainerMobile: {
    ...container,
    flexDirection: 'column'
  },
  videoLink: {
    ...videoLink,
    margin: '1%',
    width: '48%',
  },
  videoLinkMobile: {
    ...videoLink,
    margin: '1%',
    width: '98%'
  },
  link: {
    fontWeight: 'bold',
    color: colors.Theme.darkGray,
    width: '100%',
    display: 'block',
    textAlign: 'center'
  }
}
