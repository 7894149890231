import PropTypes from "prop-types";
import React from "react";
import colors from "../../colors";

const btnStyles = {
  display: 'block',
  border: 'medium none',
  textAlign: 'center',
  color: 'white',
  cursor: 'pointer',
  outline: 'none',
  margin: '10px auto 14px',
  padding: '5px 5px',
  marginLeft: 10,
  minWidth: 80,
  maxWidth: 100,
  borderRadius: 5,
  width: 'auto',
  background: colors.MARATHON.primary
}

const FileInputButton = ({name, children, onChange, styles}) => {
  return (
    <form encType="multipart/form-data" style={styles ? styles : btnStyles}>
      {children ? children :
        <label htmlFor="file-upload">
          <i className="fa fa-cloud-upload"></i> {name}
        </label>
      }
      <input id="file-upload" type="file" name={name} style={{display: 'none'}} onChange={onChange}/>
    </form>
  )
}

FileInputButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  styles: PropTypes.any,
};

export default FileInputButton;
