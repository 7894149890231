import React, {Component} from "react"
import {Button, Grid, TextField, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Api from '../../../Api/Api'
import PropTypes from "prop-types";

class SingleEList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      emails: [],
      edit: false,
      new: false,
      isDeleted: false
    }
  }

  componentDidMount() {
    var list = this.props.data
    this.setState({
      id: list.id,
      title: list.title,
      emails: list.emails,
      isDeleted: list.isDeleted,
      edit: this.props.edit ? true : false,
      new: this.props.new ? true : false
    })
  }

  editMode = () => {
    this.setState({edit: true})
  }

  handleChange = (e, t) => {
    var val = e.target.value
    this.setState({[t]: val})
  }

  handleEmailChange = (e, i) => {
    var emails = this.state.emails
    var val = e.target.value
    emails[i].email = val
    this.setState({emails: emails})
  }

  showEmails = () => {
    var elist = [];
    this.state.emails.forEach((em, i) => {
      !em.isDeleted && elist.push(
        <>
          <Grid key={i} item xs={9.5} display="flex" alignItems="center">
            <TextField onChange={(e) => {
              this.handleEmailChange(e, i)
            }} value={em.email} fullWidth label="Email"/>
          </Grid>
          <Grid item xs={1} style={{paddingTop: 0, justifyContent: 'center'}} display="flex"
                alignItems="center">
            <Button onClick={() => {
              this.delEmail(i)
            }} style={{
              padding: '10px',
              maxWidth: '20px',
              minWidth: '20px',
              maxHeight: '20px',
              marginTop: '0px',
              marginRight: '8px'
            }}>
              <DeleteIcon style={{paddingTop: '6px', cursor: 'pointer'}}/>
            </Button>
          </Grid>
        </>
      )
    })
    return elist;
  }

  saveList = (e) => {
    this.state.new ? this.newList() : this.oldList();
    this.setState({edit: false, new: false})
  }

  oldList() {
    var data = this.state;

    var oldList = {id: data.id, title: data.title, emails: data.emails, isDeleted: data.isDeleted};
    Api.updateEList(oldList);

  }

  delList = (id) => {
    var data = this.state
    this.setState({isDeleted: true}, () => {
      this.oldList();
    })
    var delList = {id: data.id, title: data.title, emails: data.emails, isDeleted: data.isDeleted};
    Api.updateEList(delList);
  }

  newList() {
    var data = this.state;
    var newList = {id: data.id, title: data.title, emails: data.emails, isDeleted: data.isDeleted};
    Api.newEList(JSON.stringify(newList));
  }

  addEmail = () => {
    var emails = this.state.emails
    emails.push({email: '', isDeleted: false})
    this.setState({emails: emails})
  }

  delEmail = (i) => {

    var emails = this.state.emails;
    emails[i].isDeleted = true;
    this.setState({emails: emails});

  }

  render() {

    return (
      <>
        {!this.state.isDeleted ?
          <Grid key={this.props.count} spacing={1.5} container
                style={{borderBottom: '1px solid #eee', padding: '10px', marginTop: 0}} alignItems="center">
            <Grid item xs={8.5} display="flex" alignItems="center"
                  style={{paddingTop: 0}}>{this.state.edit ? <TextField onChange={(e) => {
                this.handleChange(e, 'title')
              }} value={this.state.title} fullWidth label="Email List Name"/> :
              <Typography>{this.state.title}</Typography>}</Grid>
            <Grid item xs={1} style={{paddingTop: 0}}>
              <Button onClick={this.addEmail} variant="contained" style={{
                marginTop: '0',
                minWidth: '24px',
                padding: '0px',
                borderRadius: '50%',
                marginRight: '12px'
              }}>+</Button>
            </Grid>
            <Grid item xs={1} style={{paddingTop: 0, justifyContent: 'center'}} display="flex"
                  alignItems="center">
              <Button onClick={() => {
                this.delList(this.state.id)
              }} style={{
                padding: '8px',
                maxWidth: '20px',
                minWidth: '20px',
                maxHeight: '20px',
                marginTop: '-8px',
                marginRight: '8px'
              }}>
                <DeleteIcon style={{paddingTop: '6px', cursor: 'pointer'}}/>
              </Button>
            </Grid>
            <Grid item xs={1} style={{textAlign: 'right', paddingTop: 0}}>
              {this.state.edit ? <Button onClick={this.saveList}>Save</Button> :
                <Button onClick={this.editMode}>Edit</Button>}
            </Grid>
            {this.state.edit && this.showEmails()}
          </Grid>
          :
          null
        }
      </>
    )
  }
}

SingleEList.propTypes = {
  data: PropTypes.any,
  edit: PropTypes.any,
  count: PropTypes.any,
  new: PropTypes.any
};
export default SingleEList;
