import {Grid} from "@mui/material";
import PropTypes from "prop-types";

const Container = (props) => {

  return (
    <>
      <Grid container className="cardContainer" justifyContent="center" spacing={1}
            style={{marginTop: 5, marginBottom: '30px'}}>
        <Grid item xs={12} align="center">
          <h1>{props.title}</h1>
        </Grid>
        <Grid container className="contentContainer" justifyContent="center" spacing={props.spacing && 5}
              style={{marginBottom: 30}}>
          {props.children}
        </Grid>
      </Grid>
    </>
  )
}

Container.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  spacing: PropTypes.any
};


export default Container;
