import keys from "../config/keys";
import {BlobServiceClient} from "@azure/storage-blob";
import Api from "../Api/Api";
export default {
  uploadCertNew: async (file, file_name, data, quiz) => {
    const blobSasUrl = keys.SAS_URL;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient("certs");
    const blobClient = containerClient.getBlobClient(file_name);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      blobHTTPHeaders: {blobContentType: 'image/jpeg'},
      metadata: {
        quiz: data.metadata.id,
        videoName: data.metadata.videoName,
        today: data.todays_date,
        groupCode: data.metadata.isGroupCode ? 'yes' : 'no',
        issued: data.todays_date,
        quiz_db_ref: quiz?.entry_id ? quiz.entry_id.toString() : ''
      },
      onProgress: ev => console.log(ev, "PROGRESS")
    });
    const url = containerClient.getBlockBlobClient(`${file_name}`).url.split('?')[0]
    console.log(`Upload of file '${file_name}' completed}`,url, result);
    return url;
  },
  uploadImage: async (file, file_name) => {
    const blobSasUrl = keys.SAS_URL;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient("profile-pictures");
    const blobClient = containerClient.getBlobClient(file_name);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      blobHTTPHeaders: {blobContentType: 'image/jpeg'},
      onProgress: ev => console.log(ev)
    });
    console.log(`Upload of file '${file_name}' completed`);
  },
  fetchImage: async (id) => {
    try {
      const blobSasUrl = keys.SAS_URL;
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient("profile-pictures");
      const blobClient = containerClient.getBlobClient(id + '.jpg');
      const downloaded = await blobClient.download();
      const blob_file = await downloaded.blobBody;
      return URL.createObjectURL(blob_file);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
  fetchSafetyDocs: async () => {
    try {
      // Taken Quizzes

      const blobSasUrl = keys.SAS_URL;
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient("safety-documents");
      let iter = containerClient.listBlobsFlat({ includeMetadata: true  });
      const documents = {}
      for await (const item of iter) {
        // Get Blob Client from name, to get the URL
        const tempBlockBlobClient = containerClient.getBlockBlobClient(item.name);
        if(item.metadata && item.metadata.cat) {
          if(documents[item.metadata.cat]) documents[item.metadata.cat].push({name: item.name, url: tempBlockBlobClient.url, metadata: item.metadata})
          else documents[item.metadata.cat] = [{name: item.name, url: tempBlockBlobClient.url, metadata: item.metadata}]
        }
      }
      return documents;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
  fetchCertificates: async (id) => {
    try {
      // Taken Quizzes

      const blobSasUrl = keys.SAS_URL;
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient("certs");
      let iter = containerClient.listBlobsFlat({ prefix: `${id}/`, includeMetadata: true  });
      const certificates = []
      for await (const item of iter) {
        // Get Blob Client from name, to get the URL
        const tempBlockBlobClient = containerClient.getBlockBlobClient(item.name);

        if(item.metadata && item.metadata.quiz_db_ref) {
          const quiz = await Api.getQuizzes(item.metadata.quiz_db_ref).then(quizzes => {
            if(quizzes.data) return quizzes.data;
          }).catch(err => console.log(err, "ERROR"));
          certificates.push({name: item.name, url: tempBlockBlobClient.url, metadata: item.metadata, quiz})
        } else certificates.push({name: item.name, url: tempBlockBlobClient.url, metadata: item.metadata, manualUpload: true})
      }
      return certificates;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
  deleteCert: async (file) => {
    const blobSasUrl = keys.SAS_URL;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient("certs");
    const deleteBlob = containerClient.deleteBlob(file)
    console.log(deleteBlob)
    return deleteBlob;
  },
  deleteSafetyDoc: async (file) => {
    const blobSasUrl = keys.SAS_URL;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient("safety-documents");
    const deleteBlob = containerClient.deleteBlob(file)
    console.log(deleteBlob)
    return deleteBlob;
  },
  uploadCert: async (file, file_name, meta) => {
    console.log(meta, file_name, "AZURE")
    const blobSasUrl = keys.SAS_URL;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient("certs");
    const blobClient = containerClient.getBlobClient(file_name);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
      blobHTTPHeaders: {blobContentType: 'application/pdf'},
      metadata: meta
    });
    // console.log(`Upload of file '${file_name}' completed`);
    return result;
  },
  uploadSafetyDocuments: async (file, file_name, meta) => {
    console.log(meta, file_name, "AZURE")
    const blobSasUrl = keys.SAS_URL;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient("safety-documents");
    const blobClient = containerClient.getBlobClient(file_name);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadBrowserData(file, {
      blobHTTPHeaders: {blobContentType: 'application/pdf'},
      metadata: meta
    });
    // console.log(`Upload of file '${file_name}' completed`);
    return result;
  },
}
