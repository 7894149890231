import React, {useEffect, useRef, useState} from "react";
import bcrypt from "bcryptjs";
import styles from "./styles";
import Api from "../../Api/Api";
import {logIn, useUserController} from "../../context/user";
import PropTypes from "prop-types";
import {FormControl, MenuItem, Select} from "@mui/material";
import {useDevice} from "../../context/device";
import userData from "../../assets/data/user";
import {decodeToken} from "../../utils/helper";
import emailjs from 'emailjs-com';
import Icon from "@mui/material/Icon";
import {setSpanish, useMaterialUIController} from "../../context";
import language from "../../assets/data/language";

// TODO: SPLIT UP FILE BY COMPONENT

const FormInput = ({label, type, value, onChange, required, side, showPassword, setShowPassword}) => {
  let formType = type;
  if (formType === "password" && showPassword) formType = "text"
  return (
    <label id={label} htmlFor={label} style={side ? styles.form.labelSide : styles.form.label}>
          <span style={{fontSize: '1rem'}}>
              {label}
            {required && <span style={{color: 'red'}}>*</span>}
          </span>
      <div style={styles.form.inputContainer}>
        <input
          style={styles.form.input}
          type={formType}
          value={value}
          onChange={onChange}
          required={required}
        />
        {type === "password" && <div style={styles.form.inputIcon} onClick={() => setShowPassword(!showPassword)}>
          <Icon fontSize="small">{showPassword ? "visibility_off" : "visibility"}</Icon>
        </div>}
      </div>

    </label>
  )
};
FormInput.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  showPassword: PropTypes.bool,
  setShowPassword: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  required: PropTypes.bool.isRequired,
  side: PropTypes.bool
};

const formComplete = (form) => {
  const keys = Object.keys(form);
  let completed = []
  for (let k in keys) {
    if (form[keys[k]] !== "") completed.push(k);
  }
  return completed.length === keys.length;
}

const LoginForm = ({spanish, onSubmit, setApiMessage}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(email, password, "PASS")
    // e.preventDefault();
    // onSubmit({email, password});
    try {
      const data = {email, password};
      // Check if email exists in the database
      // const emailCheck = await Api.checkEmail(email);
      // if (!emailCheck.data.user) {
      //     throw new Error("Email not found");
      // }
      // console.log(emailCheck, "CHECK")
      // onSubmit(data)
      // Compare password with hashed password from the database
      await Api.login(data).then(response => {
        console.log(response);
        if (response.data && response.data.token) {
          const userToSend = decodeToken(response.data.token)
          onSubmit(userToSend)
        } else {
          setApiMessage({password: language.auth.failed(spanish)})
        }

      }).catch(err => {
        if (err.response && err.response.data.message) {
          console.log(err.response.data, "ERR")
          if (err.response.data.message.code === "P2025") {
            console.log('Not Found User')
          }
        } else {
          console.log(err.response.data, 'other error?')
          setApiMessage(err.response.data)
        }
      });
      // const passwordCheck = loginCall.data.user[0].Password;
      // const isPasswordMatch = await bcrypt.compare(password, passwordCheck);
      // if (!isPasswordMatch) {
      //     throw new Error("Incorrect password");
      // }

      // onSubmit(data)
      // console.log(loginCall, "login call")
      // setUser(loginCall.data.user[0]);
      // setUser({loggedIn: true, profile: {"UserRole": "dbo", "FirstName": "lee", "LastName":"panter", "ClassificationType": null, "Email": "leepanter@ymail.com", "Password": "test", "PhoneNumber": null, "DateOfHire": null, "JobName":null, "HomeAddress": null, "UserName":null, "ProfilePicture":null, "TrainingCertificates": null, "TrainingCard":null}})
      // Login successful
      // navigate("/", { replace: true });
      // window.location = `/`
      // console.log("Login successful");

    } catch (err) {
      console.error(err, "ERRRO");
      // throw new Error(`Error during login: ${err.message}`);
    } finally {
      // Cleanup tasks, if any
    }

  };

  useEffect(() => {
    const complete = formComplete({email, password});
    if (!complete !== disabled) {
      setDisabled(!complete)
    }
  }, [email, password]);

  return (
    <form onSubmit={handleSubmit} style={styles.form.content}>
      <FormInput
        label={language.auth.email(spanish)}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
      />
      <FormInput
        label={language.auth.password(spanish)}
        type={'password'}
        value={password}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        onChange={(e) => setPassword(e.target.value)}
        required={true}
      />
      <button
        // type="submit"
        onClick={disabled ? null : handleSubmit}
        style={disabled ? styles.form.disabledButton : styles.form.button}
      >{language.auth.login(spanish)}
      </button>
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setApiMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  spanish: PropTypes.bool.isRequired,
};

const SignupForm = ({spanish, onSubmit, setApiMessage}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [classification, setClassification] = useState("");
  const [identification, setIdentification] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      setPasswordsMatch(true);
      try {
        const salt = bcrypt.genSaltSync();
        const hashedPassword = bcrypt.hashSync(password, salt);
        const user = {
          FirstName: firstName,
          LastName: lastName,
          email: email,
          password: password,
          CompanyID: identification,
          JobClassification: classification
        };

        // let test = Api.register(user);
        await Api.register(user).then(response => {
          console.log(response);
          onSubmit(response.data)
        }).catch(err => {
          if (err.response) {
            console.log(err.response.data, "ERR")
            setApiMessage(err.response.data)
            if (err.response.data.message.email) {
              console.log('Not Found User')
            }
          } else {
            console.log(err, 'other error?')
          }
        });

        // const data = await response.json();
        // setUser(data.user);
      } catch (error) {
        console.error(error);
      }
      // onSubmit({firstName, lastName, email, password, passwordsMatch});
    } else {
      setPasswordsMatch(false);
    }
  };

  useEffect(() => {
    const complete = formComplete({
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      identification,
      classification
    });
    if (!complete !== disabled) {
      setDisabled(!complete)
    }
  }, [
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    identification,
    classification
  ]);

  return (
    <form onSubmit={handleSubmit} style={styles.form.content}>
      <div style={styles.form.sideBySide}>
        <FormInput
          label={language.auth.fName(spanish)}
          type="text"
          side
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required={true}
        />
        <FormInput
          label={language.auth.lName(spanish)}
          type="text"
          side
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required={true}
        />
      </div>
      <FormInput
        label={language.auth.email(spanish)}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
      />
      <label id="jobClassification" htmlFor="jobClassification" style={styles.form.label}>
                  <span style={{fontSize: '1rem'}}>
                      {language.auth.company(spanish)}
                    <span style={{color: 'red'}}> *</span>
                  </span>
        <FormControl>
          <Select
            style={styles.form.input}
            label={language.auth.company(spanish)}
            value={identification}
            onChange={e => setIdentification(e.target.value)}
          >
            {userData.companyId.map((id, i) => <MenuItem key={i} style={{height: 50, fontSize: '1.2rem'}}
                                                         value={id}>{id}</MenuItem>)}
          </Select>
        </FormControl>
      </label>
      <label id="jobClassification" htmlFor="jobClassification" style={styles.form.label}>
                <span style={{fontSize: '1rem'}}>
                    {language.auth.classificationJob(spanish)}
                  <span style={{color: 'red'}}>   *</span>
                </span>
        <FormControl>
          <Select
            style={styles.form.input}
            label={language.auth.classification(spanish)}
            value={classification}
            onChange={e => setClassification(e.target.value)}
          >
            {userData.classification.map((cls, i) => <MenuItem key={i} style={{height: 50, fontSize: '1.2rem'}}
                                                               value={cls}>{cls}</MenuItem>)}
          </Select>
        </FormControl>
      </label>
      <div style={styles.form.sideBySide}>
        <FormInput
          label={language.auth.password(spanish)}
          type={'password'}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          side
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required={true}
        />
        <FormInput
          label={language.auth.confirmP(spanish)}
          type={'password'}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          side
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required={true}
        />

      </div>
      <p style={styles.form.messageConstant}>{language.auth.passwordRequirement(spanish)}</p>
      {!passwordsMatch && <p style={styles.form.message}>{language.auth.nomatch(spanish)}</p>}
      <button
        onClick={disabled ? null : handleSubmit}
        style={disabled ? styles.form.disabledButton : styles.form.button}
      >{language.auth.signup(spanish)}
      </button>
    </form>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setApiMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  spanish: PropTypes.bool.isRequired
};

const ApiMessage = ({spanish, message}) => {
  // console.log(message, "MESSAGFE")
  return (
    <div style={styles.apiMessage}>
      {(message && message.email) && <>
        <p style={styles.apiMessageHeading}>{message.email}</p>
      </>}
      {(message && message.token) && <>
        <p style={styles.apiMessageHeading}>{message.token}</p>
      </>}
      {(message && message.password) && <>
        <p style={styles.apiMessageHeading}>{Array.isArray(message.password) ? "Password" : message.password}</p>
        {Array.isArray(message.password) && <ul style={styles.apiMessageUl}>
          {message.password.map((msg, i) => <li key={i} style={styles.apiMessageLi}>{msg}</li>)}
        </ul>}

      </>}

    </div>
  )
}
ApiMessage.propTypes = {
  message: PropTypes.object.isRequired,
  spanish: PropTypes.bool.isRequired,
};

const PasswordReset = ({spanish, setApiMessage, allDone}) => {
  const [disabled, setDisabled] = useState(true);
  const [verification, setVerification] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [step, setStep] = useState(0);
  const verification_code = useRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const sendEmail = (token) => {
    emailjs.send("service_27j4zif","template_w4p8sts", {
      code: token,
      email: email,
    }, 'uLBRMNt_Pnb3kQlS7').then((result) => {
      console.log(result, "RES")
    }, (error) => {
      console.log(error)
    });
  }

  // const reset = () => {
  //   setStep(0);
  //   allDone()
  // }


  // TODO: Merge these 3 functions into one
  const emailReset = () => {
    Api.reset_password({
      phase: 1,
      email
    }).then(res => {
      if (res.data && res.data.code) {
        verification_code.current = res.data.code;
        sendEmail(res.data.code);
      }
    }).catch(err => console.log(err));
    setStep(1);
  }

  const checkCode = () => {
    Api.reset_password({
      phase: 2,
      email,
      codeToVerify: verification
    }).then(res => {
      if (res.data) {
        console.log(res.data, "data")
        if (res.data === "OK") setStep(2)

      }
    }).catch(err => console.log(err));
  }

  const changePassword = () => {
    Api.reset_password({
      phase: 3,
      password,
      email,
      confirm_password: confirmPassword
    }).then(res => {
      if (res.data) {
        if (res.data === "OK") allDone()
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response.data, "ERR")
        setApiMessage(err.response.data)
      }
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 0) emailReset();
    if (step === 1) checkCode();
    if (step === 2) {
      if (password === confirmPassword) {
        setPasswordsMatch(true);
        changePassword();
      } else setPasswordsMatch(false)
    }

    setDisabled(true);
  };


  useEffect(() => {
    if (step === 0 && email.includes('@')) setDisabled(false);
    if (step === 1 && verification.length === 6) setDisabled(false);
    if (step === 2 && (confirmPassword === password)) setDisabled(false);

  }, [
    verification,
    email,
    confirmPassword,
    password
  ]);

  return (
    <form style={styles.form.content}>
      {step === 0 && <FormInput
        label={language.auth.email(spanish)}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
        spanish={spanish}
      />}
      {step === 1 && <>
        <p style={styles.verificationMsg}>{language.auth.verifyMsg(spanish)}</p>
        <FormInput
          label={language.auth.verifyCode(spanish)}
          type="number"
          value={verification}
          onChange={(e) => setVerification(e.target.value)}
          required={true}
          spanish={spanish}
        />
      </>}
      {step === 2 && <>
        <FormInput
          label={language.auth.password(spanish)}
          type={'password'}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          side
          onChange={(e) => setPassword(e.target.value)}
          required={true}
          spanish={spanish}
        />
        <FormInput
          label={language.auth.confirmP(spanish)}
          type={'password'}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          side
          onChange={(e) => setConfirmPassword(e.target.value)}
          required={true}
          spanish={spanish}
        />
        <p style={styles.form.messageConstant}>{language.auth.passwordRequirement(spanish)}</p>
        {!passwordsMatch && <p style={styles.form.message}>{language.auth.nomatch(spanish)}</p>}
      </>}
      <button
        // type="submit"
        onClick={disabled ? null : handleSubmit}
        style={disabled ? styles.form.disabledButton : styles.form.button}
      >
        {step === 0 ? language.auth.code(spanish) : language.auth.submit(spanish)}
      </button>
    </form>
  )
}
PasswordReset.propTypes = {
  onSubmit: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  spanish: PropTypes.bool.isRequired,
  setApiMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  allDone: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

const Index = () => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);
  const [apiMessage, setApiMessage] = useState(false);
  // const {user, login, signup, logout} = useContext(AuthContext);
  const [userController, userDispatch] = useUserController();
  const {user} = userController;
  const [menuController, menuDispatch] = useMaterialUIController();
  const {spanish} = menuController;
  const device = useDevice();
  const handleLoginSubmit = (formData) => {
    logIn(userDispatch, formData)
    window.location = "/videos"
  };

  const handleSignupSubmit = (formData) => {
    const userToSave = {
      email: formData.Email,
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      CompanyID: formData.CompanyID,
      JobClassification: formData.JobClassification,
      UserId: formData.UserId
    }
    logIn(userDispatch, userToSave);
    window.location = "/videos"
  };

  const handleSpanish = () => setSpanish(menuDispatch, !spanish);

  const goBack = () => {
    setIsLoginForm(true);
    setResetPassword(false);
  }

  const title = resetPassword ? language.auth.reset(spanish) : isLoginForm ? language.auth.login(spanish) : language.auth.signup(spanish);
  return (
    <div style={device === "mobile" ? styles.authContainerMobile : styles.authContainer}>
      <h1 style={device === "mobile" ? styles.authHeaderMobile : styles.authHeader}>
        {(resetPassword || !isLoginForm) &&
          <Icon fontSize="medium" onClick={goBack} style={{cursor: 'pointer'}}>arrow_back</Icon>}
        {title}
        <button
          onClick={handleSpanish}
          style={styles.languageBtn}
        ><Icon style={{marginRight: 3}}>language</Icon>{spanish ? "English" : "Español"}</button>
      </h1>

      <div style={styles.form.formContent}>
        {apiMessage && <ApiMessage spanish={spanish} message={apiMessage}/>}
        {resetPassword ? <PasswordReset spanish={spanish} setApiMessage={setApiMessage} allDone={() => {
          setIsLoginForm(true);
          setResetPassword(false);
        }}/> : <>
          {isLoginForm ? (
            <>
              <LoginForm onSubmit={handleLoginSubmit} setApiMessage={setApiMessage} spanish={spanish}/>
              <a onClick={() => setResetPassword(true)} style={{...styles.form.bottomLink, marginBottom: 10}}>
                {language.auth.forgot(spanish)}
              </a>
            </>
          ) : (
            <SignupForm onSubmit={handleSignupSubmit} setApiMessage={setApiMessage} spanish={spanish}/>
          )}
        </>}
        {!resetPassword && <a onClick={() => setIsLoginForm(!isLoginForm)} style={styles.form.bottomLink}>
          {isLoginForm ? language.auth.noaccount(spanish) : language.auth.account(spanish)}
        </a>}
      </div>

    </div>
  );
};


export default Index;
