import React from "react";

export default {
  classification: [
    "Helper",
    "Apprentice 1",
    "Apprentice 2",
    "Apprentice 3",
    "Apprentice 4",
    "Mechanic",
    "Journeyman",
    "Service Technician",
    "Senior Service Tech",
    "Low Voltage Tech",
    "Low Voltage Systems Eng",
    "Senior Low Voltage Tech",
    "Foreman",
    "Superintendent",
    "General Superintendent",
    "Project Managers",
    "Division Managers",
    "Safety",
    "Office/Clerical",
    "Fleet",
    "Operator",
    "President",
    "Vice President",
    "QAQC",
    "Fab Shop"
  ],
  companyId: [
    "Marathon",
    "CrewBelt",
    "SubContractor"
  ]
}
