import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Chart} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Soft UI Context Provider
import {MaterialUIControllerProvider} from "context";
import {UserControllerProvider} from "context/user";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ResponsiveContextProvider} from "./context/device";

// Register the plugin to all charts:
Chart.register(ChartDataLabels);

ReactDOM.render(
  <BrowserRouter>
    <ResponsiveContextProvider>
      <MaterialUIControllerProvider>
        <UserControllerProvider>
          <App/>
        </UserControllerProvider>
      </MaterialUIControllerProvider>
    </ResponsiveContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
