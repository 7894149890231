import React, {Component} from "react"
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Api from '../../../Api/Api'
import PropTypes from "prop-types";

class SingleCat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      spanish: '',
      password: '',
      quizzes: false,
      quiz: false,
      edit: false,
      new: false,
      isDeleted: false
    }
  }

  componentDidMount() {
    var cat = this.props.data
    this.setState({
      id: cat.id,
      title: cat.title,
      spanish: cat.spanish,
      isDeleted: cat.isDeleted,
      quizzes: cat.quizzes,
      quiz: cat.quiz ? [cat.quiz] : [],
      password: cat.password,
      edit: this.props.edit ? true : false,
      new: this.props.new ? true : false
    })

  }

  editMode = () => {
    this.setState({edit: true})
  }

  handleChange = (e, type) => {
    var val = e.target.value
    this.setState({[type]: val})
  }

  saveCat = (e) => {
    this.state.new ? this.newCat() : this.oldCat();
    this.setState({edit: false, new: false})
  }

  oldCat() {
    var data = this.state;

    var newCat = {
      id: data.id,
      title: data.title,
      spanish: data.spanish,
      password: data.password,
      isDeleted: data.isDeleted,
      quiz: data.quiz
    };
    Api.updateCat(newCat);

  }

  delCat = (id) => {
    var data = this.state
    this.setState({isDeleted: true}, () => {
      this.oldCat();
    })
    var delCat = {
      id: data.id,
      title: data.title,
      spanish: data.spanish,
      password: data.password,
      isDeleted: data.isDeleted,
      quiz: data.quiz
    };
    Api.updateCat(delCat);
  }

  newCat() {
    var data = this.state;
    var newCat = {id: data.id, title: data.title, spanish: data.spanish, isDeleted: false, quiz: data.quiz};
    Api.newCat(JSON.stringify(newCat));
  }

  handleQuizSelection(e, tag) {
    console.log(e.target.value, "VAL")
    let newQuiz = []
    if(e.target.value !== "") newQuiz = [e.target.value];
    this.setState({quiz: newQuiz})

  }
  render() {

    return (
      <>
        {!this.state.isDeleted ?
          <Grid key={this.props.count} spacing={1.5} container
                style={{borderBottom: '1px solid #eee', padding: '10px', marginTop: 0}} alignItems="center">
            <Grid item xs={9.5} display="flex" alignItems="center"
                  style={{paddingTop: 0}}>{this.state.edit ? <TextField onChange={(e) => {
                this.handleChange(e, 'title')
              }} value={this.state.title} fullWidth label="Title (English)"/> :
              <Typography>{this.state.title}</Typography>}</Grid> <Grid item xs={1}
                                                                        style={{paddingTop: 0}}>
            <Button onClick={() => {
              this.delCat(this.state.id)
            }} style={{
              padding: '8px',
              maxWidth: '20px',
              minWidth: '20px',
              maxHeight: '20px',
              marginTop: '-8px',
              marginRight: '8px'
            }}>
              <DeleteIcon style={{paddingTop: '6px', height: 30,   cursor: 'pointer'}}/>
            </Button>
          </Grid>
            <Grid item xs={1} style={{textAlign: 'right', paddingTop: 0}}>
              {this.state.edit ? <Button onClick={this.saveCat}>Save</Button> :
                <Button onClick={this.editMode}>Edit</Button>}
            </Grid>
            {this.state.edit &&
              <Grid item xs={9.5} display="flex" alignItems="center"><TextField onChange={(e) => {
                this.handleChange(e, 'spanish')
              }} value={this.state.spanish} fullWidth label="Title (Spanish)"/></Grid>}
            {this.state.edit &&
              <Grid item xs={9.5} display="flex" alignItems="center">
                <FormControl fullWidth>
                  <InputLabel>Quizzes</InputLabel>
                  {this.state.quizzes && <Select
                    value={this.state.quiz || []}
                    label="Quizzes"
                    onChange={(e) => {
                      this.handleQuizSelection(e, 'quizzes')
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {this.state.quizzes.map((q, i) => <MenuItem key={i} value={q.id}>{q.english.title}</MenuItem>)}
                  </Select>}
                </FormControl>

              </Grid>}
            {this.state.edit &&
              <Grid item xs={9.5} display="flex" alignItems="center"><TextField onChange={(e) => {
                this.handleChange(e, 'password')
              }} value={this.state.password} fullWidth label="Password (optional)"/></Grid>}
          </Grid>
          :
          null
        }
      </>
    )
  }
}

SingleCat.propTypes = {
  data: PropTypes.any,
  edit: PropTypes.any,
  count: PropTypes.any,
  new: PropTypes.any
};

export default SingleCat;
