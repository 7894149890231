import * as React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import {Grid} from '@mui/material';
import {resetActiveQuiz, setActiveQuiz, useUserController} from "../context/user";
import {useMaterialUIController} from "../context";
import Icon from "@mui/material/Icon";


// Alex, what is this line below? when i build with this included it fails as it is not there.
// i commented it out and all seems to work well. 
// import 'video.js/dist/video-js.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100vw',
  height: '100vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  padding: '10px',
  background: "#f7f7f7",
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // pt: 1,
  // px: 1,
  // pb: 1,
  li: {
    listStyleType: 'decimal'
  },
  h2: {
    textAlign: 'center'
  }
};

export default function NestedModal(p) {
  var data = p.data
  const startTime = React.useRef(false);
  const [videoLoading, setVideoLoading] = React.useState(true);
  const [userController, userDispatch] = useUserController();
  const {user, activeQuiz} = userController;

  const [controller, dispatch] = useMaterialUIController();
  const {spanish} = controller;

  var o = user.vData ? user.vData : data

  let navigate = useNavigate();


  const [opened, setOpened] = React.useState(false);
  const [video, setVideo] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [play, setPlay] = React.useState(false)
  const [theVideo, setTheVideo] = React.useState(null)


  React.useEffect(() => {
    initialize();
  })

  React.useEffect(() => {
    if (!video && play) setPlay(false);
    if (!video && finished) setFinished(false);
  }, [video])

  const handleOpen = () => {
    setOpened(true);
    // user.updateVData(data)
    // user.updateCrwUrl(window.location.pathname)
    // user.updateCid(data.cat)
  };

  const initialize = async () => {
    await setVidContainer();
    // if(!videoLoading) setVideoLoading(true)
    p.rewatch && setOpened(true)
    p.rewatch && user.vData && setVideo(true)
  }

  const setVidContainer = async () => {
    !theVideo && setTheVideo(document.getElementById('training-vid'))
  }

  const handleClose = () => {
    setVideo(false);
    // user.updateVData(null)
    // setVideoLoading(true);
    setOpened(false);
    resetActiveQuiz(userDispatch);

  };


  const showVideo = () => {
    setVideo(true);
  };

  const setVid = () => {
    setActiveQuiz(userDispatch, {
      id: o.id,
      videoId: p.data.id,
      videoName: p.data.english.title,
      videoStartTime: startTime.current,
      videoEndTime: new Date()
    })
  }

  const trackProgress = async (active) => {
    let vid = document.getElementById("training-vid");
    console.log(vid.duration, "DURATION")
    let i = 0;
    if(vid) await (async () => {

      while (await new Promise(resolve => setTimeout(async () => {
        console.log(vid.currentTime, active, "VID DUR");
        resolve(i++)
      }, 1000)) < vid.duration-10) {
        if (vid.currentTime === vid.duration-10 || video) break;
      }
    })();
  }

  const playPause = () => {
    if (!startTime.current) startTime.current = new Date();
    let el = document.getElementById("training-vid");
    setPlay(!play)
    if (play) el.pause()
    else el.play()
    setActiveQuiz(userDispatch, {
      id: o.id,
      videoId: p.data.id,
      userId: user.id,
      videoName: p.data.english.title,
      videoStartTime: startTime.current || new Date()
    })
    // trackProgress({
    //   id: o.id,
    //     videoId: p.data.id,
    //     userId: user.id,
    //     videoName: p.data.english.title,
    //     videoStartTime: startTime.current || new Date()
    // })
    // play ? theVideo.pause() : theVideo.play()
  }

  const fullScreen = () => {
    let el = document.getElementById("training-vid");
    if(el.webkitEnterFullScreen){
      el.webkitEnterFullScreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    }

    // theVideo.requestFullscreen(); // activate fullscreen mode
  }

  const videoFinished = () => {
    setFinished(true);
    setVid()
  };

  const goToTest = () => {
    // activeQuiz.qid = o.id
    // setActiveQuiz(userDispatch, o.id)
    setVid()

    navigate(`/quiz/`)
  }
  const getVideoSource = () => {
    console.log("loaded")
    setVideoLoading(false)
  }

  function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>{spanish ? 'Iniciar vídeo' : 'Start Video'}</Button>
      <Modal
        open={opened}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        color="primary"
      >
        {video === false ?
          <Box sx={style}>
            <h2 id="parent-modal-title" style={{textAlign: 'left'}}>{spanish ? 'INSTRUCCIONES' : 'INSTRUCTIONS'}</h2>
            <div style={{
              position: 'absolute',
              top: 10,
              right: 10,
              cursor: 'pointer',
              fontWeight: 800,
              color: '#EB1F23',
              border: '1px solid #EB1F23',
              borderRadius: '4px',
              padding: '0px 5px'
            }} onClick={() => {
              handleClose()
            }}>{spanish ? 'CERRAR' : 'CLOSE'}</div>
            <span id="parent-modal-description">
            <ul style={{paddingLeft: 25}}>
              <li>
                {spanish ? 'Presiona Inicio y mira el video instructivo hasta el final. El video no se puede adelantar ni saltar.' : 'Press Start and watch the instructional video until the end. The video cannot be fast-forwarded or skipped.'}
              </li>
              <li>
                {spanish ? 'Después del video, continúe y responda el cuestionario. Para aprobar, se requiere una puntuación de 70% o más.' : 'After the video, continue to and take the quiz. To pass, a score of 70% or more is required.'}
              </li>
              <li>
                {spanish ? 'Después de realizar el cuestionario, se mostrarán sus resultados. Si se aprueba, se le enviará un certificado por correo electrónico. De lo contrario, la opción de volver a ver el video estará disponible y se podrá volver a realizar el cuestionario.' : 'After taking the quiz, your results will be displayed. If passed, a certificate will be emailed to you. If not, the option to re-watch the video will be available, and the quiz can be retaken.'}
              </li>
            </ul>
          </span>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20
            }}>
              <Button variant="contained"
                      onClick={showVideo}>{spanish ? 'Iniciar vídeo' : 'Start Video'}</Button>
            </div>
          </Box>
          :
          <Grid container sx={{...style, justifyContent: 'space-between', textAlign: 'center', flexDirection: 'row'}}>
            <Grid item xs={12}>
              <p id="parent-modal-description">
                {spanish ? 'Este video no se puede pausar ni avanzar rápidamente. Mire el video completo para continuar con el cuestionario.' : 'This video cannot be paused or fast-forwarded. Please watch the video entirely to continue to the quiz.'}
              </p>
              <h2 id="parent-modal-title"
                  style={{textAlign: 'center'}}>{spanish ? o.spanish.title : o.english.title}</h2>
              <div style={{
                position: 'absolute',
                top: 10,
                right: 10,
                cursor: 'pointer',
                fontWeight: 800,
                color: '#EB1F23',
                border: '1px solid #EB1F23',
                borderRadius: '4px',
                padding: '0px 5px'
              }} onClick={() => {
                handleClose()
              }}>CLOSE
              </div>
            </Grid>
            <div style={{width: "100%", textAlign: 'center'}}>
              {/*eslint-disable-next-line react/no-unknown-property*/}
              <video
                id="training-vid"
                playsInline
                onEnded={videoFinished}
                width="100%"
                disablePictureInPicture
                onLoadedData={getVideoSource}
                style={{maxWidth: '100%', maxHeight: '75vh'}}
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload nofullscreen noremoteplayback"
              >
                <source src={(spanish && o.spanish.video !== "") ? o.spanish.video : o.english.video} type="video/mp4"
                        id='video-noclick'></source>
              </video>
            </div>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
              <Button variant="contained" onClick={goToTest}
                      disabled={!finished}>{spanish ? 'INICIAR EXAMEN' : 'START QUIZ'}</Button>
              <Button variant="contained"
                      onClick={playPause}>
                <Icon fontSize="large" style={{marginRight: 5}}>{play ? "pause" : "play_arrow"}</Icon>
                {spanish ? play ? 'PAUSAR VIDEO' : 'REPRODUCIR VIDEO' : play ? 'PAUSE VIDEO' : 'PLAY VIDEO'}

              </Button>
              {!iOS() && <Button variant="contained"
                       onClick={fullScreen}>
                <Icon fontSize="large" style={{marginRight: 5}}>fullscreen</Icon>
                {spanish ? 'pantalla completa' : 'FULL SCREEN'}
              </Button>}
            </Grid>
          </Grid>}
          </Modal>
          </div>
          );
        };
