/* eslint-disable prefer-template */
/* eslint-disable radix */
import jwtDecode from "jwt-decode";

export const decodeToken = (token) => {
  if (token) {
    try {
      const decodedData = jwtDecode(token);
      return decodedData;
    } catch (err) {
      console.log("decodeToken err", err);
      return null;
    }
  } else {
    console.log("No token to decode");
    return null;
  }
};

export const downloadCsvFile = (dataStr, fileName, fileType = "text/csv") => {
  const blob = new Blob([dataStr], {type: fileType});
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const parseQueryString = (queryStr) => {
  try {
    return JSON.parse(
      '{"' +
      decodeURI(queryStr).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
      '"}'
    );
  } catch (err) {
    console.log("error while parsing query string", err);
    return {};
  }
};


export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};
