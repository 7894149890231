import colors from "../../colors";
const authHeader = {
  backgroundColor: colors.MARATHON.primary,
  marginBottom: 10,
  color: "white",
  textAlign: "center",
  position: "relative",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: 'center',
  padding: "8px 20px",
  marginTop: 0,
  fontWeight: 700,
  fontSize: '1.2rem',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(to right bottom, #D43D32, #D43D32)'
}

const button = {
  display: 'block',
  border: 'medium none',
  padding: '12px 16px',
  textAlign: 'center',
  color: 'white',
  cursor: 'pointer',
  outline: 'none',
  minWidth: 152,
  width: 'auto',
  margin: '10px auto 14px'
}
export default {
  authContainer: {
    opacity: 1,
    transform: 'translateY(-50%) translateX(-50%) translate3d(0px, 0px, 0px)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginRight: '-50%',
    width: '95%',
    maxWidth: '390px',
    background: '#fff',
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 8px -4px',
    padding: 0,
    marginBottom: 0
  },
  languageBtn: {
    border: 'medium none',
    textAlign: 'center',
    color: 'white',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: 'pointer',
    outline: 'none',
    width: 'auto',
    borderRadius: 5,
    padding: '2px 8px',
    background: 'rgba(255,255,255,0.2)',
    margin: 0,
    fontSize: '.9rem'
  },
  authHeader: {
    ...authHeader,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4
  },
  authHeaderMobile: {
    ...authHeader,
  },
  authContainerMobile: {
    overflow: 'scroll'
  },
  apiMessageHeading: {
    fontWeight: 700
  },
  apiMessageUl: {},
  apiMessage: {
    fontSize: '.8rem',
    color: colors.MARATHON.primary,
    paddingLeft: 10
  },
  verificationMsg: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: '.9rem'
  },
  apiMessageLi: {},
  form: {
    inputContainer: {
      position: 'relative'
    },
    inputIcon: {
      position: 'absolute',
      top: 5,
      right: 5,
      color: colors.Theme.black,
      cursor: 'pointer'
    },
    content: {
      padding: '16px 12px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 10
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10
    },
    labelSide: {
      width: '48%',
      marginBottom: 10
    },
    input: {
      transition: 'box-shadow 0.2s ease 0s',
      width: '100%',
      padding: 8,
      height: 33.3,
      border: '1px solid rgba(0, 22, 66, 0.32)',
      background: 'rgb(246, 249, 250)',
      outline: 'none',
      maxWidth: '100%',
      borderRadius: 2
    },
    button: {
      ...button,
      background: '#D43D32'
    },
    disabledButton: {
      ...button,
      cursor: 'not-allowed',
      backgroundColor: 'grey'
    },
    bottomLink: {
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '1rem'
    },
    message: {
      textAlign: 'center',
      margin: 0,
      color: '#D43D32'
    },
    messageConstant: {
      textAlign: 'center',
      fontSize: '.9rem',
      margin: 0,
      marginBottom: 10,
      color: '#949494'
    },
    sideBySide: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  }
}
