import React, {Component} from 'react';
import {Radio} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from "prop-types";


class TrueFalse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: null
    }
  }

  showOptions(o) {
    let opts = [];
    opts.push(<FormControlLabel key={1} value={true} control={<Radio/>}
                                label={this.props.span ? "Verdadero" : "True"}/>)
    opts.push(<FormControlLabel key={2} value={false} control={<Radio/>}
                                label={this.props.span ? "Falsa" : "False"}/>)
    return opts;
  }

  handleChange = (e) => {
    e.target.value == 'true' ? this.props.cb({
      'q': this.props.count,
      'a': '0'
    }) : this.props.cb({'q': this.props.count, 'a': '1'});
  }

  render() {
    let q = this.props;
    return (
      <li key={q.qid}>
        <h3>
          {q.q.title}
        </h3>
        <FormControl>
          <RadioGroup onChange={this.handleChange}>
            {this.showOptions(q.answer)}
          </RadioGroup>
        </FormControl>
      </li>
    )
  }
}

TrueFalse.propTypes = {
  q: PropTypes.any,
  cb: PropTypes.any,
  span: PropTypes.any,
  answer: PropTypes.any,
  count: PropTypes.any,
  qid: PropTypes.any
}

export default TrueFalse
