import DataTable from 'react-data-table-component';
import styles from "./styles";
import React, {useEffect, useState} from "react";
import Api from "../../../../Api/Api";
import PropTypes from "prop-types";
import colors from "../../../../colors";
import CreateUser from "../CreateUser";
import Icon from "@mui/material/Icon";
import Certificates from "../../profile/Certificates";
import {downloadCsvFile} from "../../../../utils/helper";
import azure from "../../../../utils/azure";
import NotifyUsers from "../NotifyUsers";


const ExpandedComponent = ({data}) => {
  return <div style={styles.expandable}>
    <Certificates user={{...data, id: data.UserID}} />
  </div>;
}

ExpandedComponent.propTypes = {
  data: PropTypes.object.isRequired
};

function MecAdmin(props) {
  const [processing, setProcessing] = useState(false);
  const [userData, setUserData] = useState(false);
  const [message, setMessage] = useState(false);
  const [terminated, setTerminated] = useState(false);
  const [filterText, setFilterText] = useState('');
  const {fetchCertificates} = azure;

  let defaultColumns = [
    {
      name: 'Email',
      selector: row => row.Email,
      sortable: true
    },
    {
      name: 'First Name',
      selector: row => row.FirstName,
      sortable: true
    },
    {
      name: 'Last Name',
      selector: row => row.LastName,
      sortable: true
    },
    {
      name: 'Job Classification',
      selector: row => row.JobClassification,
      sortable: true
    },
    {
      name: 'Job Name',
      selector: row => row.JobName,
      sortable: true
    },
    {
      name: 'Company',
      selector: row => row.CompanyID,
      sortable: true
    },
    {
      name: 'Profile',
      selector: row => <button
        onClick={(e) => window.open("/profile?email=" + row.Email, '_blank')}
        style={styles.btn}
      >View</button>
    }
  ];
  const [columns, setColumns] = useState(defaultColumns)
  const [createUser, setCreateUser] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const getUsers = async (query) => {
    await Api.getAllUsers(query).then(response => setUserData(response.data)).catch(err => console.log(err))
  }

  if (!userData) getUsers()

  const terminateUser = (e, user) => {
    if(terminated) {
      alert(`Are you sure you want to delete ${user.Email}? Once this is done the user cannot be recovered. A new account will have to be made for this email.`)
      Api.delete(user.UserID).then(done => getUsers("?terminated=yes")).catch(err => console.log(err));
    } else {
      alert(`Are you sure you want to terminate ${user.Email}?`)
      Api.terminate(user.UserID).then(done => getUsers()).catch(err => console.log(err));
    }

  }
  const reinstateUser = (e, user) => {
    alert(`Are you sure you want to reinstate ${user.Email}?`)
    Api.reinstate(user.UserID).then(done => {
      getUsers()
      setTerminated(false)
    }).catch(err => console.log(err));

  }

  const showTerminated = (bool) => {
    setTerminated(bool)
    if (bool) getUsers("?terminated=yes")
    else getUsers()
  }

  const setAdmin = (e, user) => {
    Api.makeAdmin(user.UserID).then(done => {
      getUsers()
    }).catch(err => console.log(err));
  }
  const clearLocked = (e, user) => {
    Api.reset_locked(user.Email).then(done => {
      setMessage(`Reset of ${user.Email} login attempts done`)
      getUsers();
      setTimeout(() => setMessage(false), 5000)
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    let newColumns = [...defaultColumns];
    if(terminated) newColumns.push({
      name: 'Reinstate',
      selector: row => <button
        name="reinstate"
        style={{...styles.btn, backgroundColor: "transparent", color: colors.MARATHON.primary}}
        onClick={e => reinstateUser(e, row)}
      >
        <Icon fontSize="medium">person_add</Icon>
      </button>
    })
    else {
      newColumns = newColumns.filter(c => c.name !== "Reinstate");
    }

    if(props.type === "Admin+") {
      newColumns.push({
        name: 'Make Admin',
        selector: row => <button
          name="Make Admin"
          style={{...styles.btn, backgroundColor: "transparent", color: colors.MARATHON.primary}}
          onClick={e => row.Admin && (row.Admin === "Admin" || row.Admin === "Admin+") ? null : setAdmin(e, row)}
        >
          {row.Admin && (row.Admin === "Admin" || row.Admin === "Admin+") ? row.Admin :
            <Icon fontSize="medium">admin_panel_settings</Icon>}
        </button>
      })
      newColumns.push({
        name: terminated ? 'Archive' : 'Terminate',
        selector: row => <button
          name="remove"
          style={{...styles.btn, backgroundColor: "transparent", color: colors.MARATHON.primary}}
          onClick={e => terminateUser(e, row)}
        >
          <Icon fontSize="medium">{terminated ? "delete" : "person_remove"}</Icon>
        </button>
      })
      newColumns.push({
        name: 'Clear Login Attempts',
        selector: row => <button
          name='Clear Login Attempts'
          style={{...styles.btn, backgroundColor: "transparent", color: colors.MARATHON.primary}}
          onClick={e => clearLocked(e, row)}
        >
          <Icon fontSize="medium">lock_reset</Icon>
        </button>
      })
    }
    else {
      // newColumns = newColumns.filter(c => c.name !== "Reinstate");
    }
    setColumns(newColumns);
  }, [terminated])

  const filteredItems = (userData && filterText != "") ? userData.filter(
    item => {
      const enabled = ['CompanyID', 'FirstName', 'LastName', 'Email', 'JobClassification', 'JobName']
      const possible_rows = Object.keys(item);
      let toReturn = false;
      for (let row in possible_rows) {
        const key = possible_rows[row]
        if (enabled.includes(key)) {
          let value = item[key]
          if (value && value !== null && value.toString().toLowerCase().includes(filterText.toLowerCase())) toReturn = item;
        }
      }
      return toReturn
    }
  ) : userData;

  const exportUsers = async (e) => {
    if(filteredItems) {
      setProcessing(true);
      const filename = "users.csv";
      const data = ["Training Topic, Classification, Name, Email, Date of Completion, Date of Expiration, Job Name, Company"];
      for(let user in filteredItems) {
        const userID = filteredItems[user].UserID
        let userRow = {
          topic: '',
          classification: filteredItems[user].JobClassification,
          name: filteredItems[user].FirstName + " " + filteredItems[user].LastName,
          email: filteredItems[user].Email,
          dateOfCompletion: '',
          expiration: '',
          jobName: filteredItems[user].JobName,
          companyName: filteredItems[user].CompanyID
        };
        const certificates = await fetchCertificates(userID).then(certs => certs).catch(err => console.log(err));
        if(certificates.length > 0) {
          for(let c in certificates) {
            let certificate = certificates[c]
            if(certificate.metadata) {
              userRow.topic = certificate.metadata.quizName || certificate.metadata.videoName || "";
              userRow.dateOfCompletion = certificate.metadata.issued || "";
              data.push(`${userRow.topic}, ${userRow.classification}, ${userRow.name}, ${userRow.email}, ${userRow.dateOfCompletion}, ${userRow.expiration}, ${userRow.jobName}, ${userRow.companyName}`)
            }
          }
        } else data.push(`${userRow.topic}, ${userRow.classification}, ${userRow.name}, ${userRow.email}, ${userRow.dateOfCompletion}, ${userRow.expiration}, ${userRow.jobName}, ${userRow.companyName}`)
        data.push("")
      }
      downloadCsvFile(
        data.join("\n"),
        filename
      )
      setProcessing(false);
    }
  };


  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div style={styles.toolbar}>
        {userData && <>
          <input
            style={{
              transition: 'box-shadow 0.2s ease 0s',
              padding: 8,
              border: '1px solid rgba(0, 22, 66, 0.32)',
              background: 'rgb(246, 249, 250)',
              outline: 'none',
              borderRadius: 2
            }}
            autoComplete="off"
            placeholder="Search Users"
            onChange={e => setFilterText(e.target.value)}
            value={filterText}
          />
          <button
            style={{...styles.btn, backgroundColor: colors.Theme.lightGray, color: colors.Theme.black}}
            onClick={(e) => showTerminated(!terminated)}
          >{terminated ? 'Hide' : 'Show'} Terminated Users</button>
          {!createUser && <button
            style={{...styles.btn, backgroundColor: colors.Theme.successLight}}
            onClick={(e) => setCreateUser(true)}
          >Add User</button>}
          <button
            onClick={exportUsers}
            style={{...styles.btn, backgroundColor: colors.Theme.blue}}
          >{processing ? "...Processing" : "Export"}</button>
        </>}
      </div>

    );
  }, [filterText, resetPaginationToggle, createUser, terminated, userData, processing]);

  const userCreated = () => {
    setCreateUser(false);
    getUsers().then(done => done).catch(err => console.log(err));
  }

  return (
    <div style={styles.container}>
      {message && <p style={{fontSize: 13}}>{message}</p>}
      {createUser && <CreateUser cancel={() =>  setCreateUser(false)} onSubmit={userCreated}/>}
      {userData && <DataTable
        title="Users"
        columns={columns}
        data={filteredItems}
        expandableRows
        // selectableRows
        pagination
        // dense
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        highlightOnHover
        pointerOnHover
        expandableRowsComponent={ExpandedComponent}
      />}
      <NotifyUsers />
    </div>

  );
}

MecAdmin.propTypes = {
  type: PropTypes.string,
  user: PropTypes.object
}

export default MecAdmin;
