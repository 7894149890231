import React from "react";
import PropTypes from "prop-types";
import {Card, CardContent, Grid} from "@mui/material";
import QuizList from "./QuizList";
import CatList from "./CatList";
import EmailList from "./EmailList";
import Container from "../../../oldComponents/Container";
import PassGate from "../../../oldComponents/PassGate";

function Admin({type, user}) {

  return (
    <Container spacing>
      <Grid item xs={12} md={7}>
        <Card>
          <CardContent>
            <QuizList type={type}/>
          </CardContent>
        </Card>
        {/*{type == "Admin+" && <Card>*/}
        {/*  <CardContent>*/}
        {/*    <CatList/>*/}
        {/*  </CardContent>*/}
        {/*</Card>}*/}
      </Grid>
      {type == "Admin+" && <>
        <Grid item xs={12} md={5}>
          <Card>
            <CardContent>
              <CatList/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}></Grid>
      </>}
      {/*<Grid item xs={12} md={7}>*/}
      {/*  <Card>*/}
      {/*    <CardContent>*/}
      {/*      <EmailList/>*/}
      {/*    </CardContent>*/}
      {/*  </Card>*/}
      {/*</Grid>*/}

    </Container>
  )
}

Admin.propTypes = {
  type: PropTypes.string.isRequired,
  user: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
}

export default Admin;
