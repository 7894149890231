import Card from "@mui/material/Card";
import "flatpickr/dist/flatpickr.css";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import {useUserController} from "context/user";
import Admin from "./Admin";
import MDTypography from "../../../components/MDTypography";
import MecAdmin from "./MecAdmin";

function Home() {
  const [userController] = useUserController();
  const {user} = userController;
  const adminType = user.Admin;

  if (userController.user) {
    return (
      <DashboardLayout>
        <DashboardNavbar/>
        <MDBox py={0}>
          <Card>
            <MDBox p={3} lineHeight={1} height="80vh" style={{overflow: 'scroll'}}>
              <MDTypography variant="h5">Admin Panel</MDTypography>
              <MecAdmin type={adminType} user={user}/>
              <Admin type={adminType} user={user}/>
            </MDBox>
          </Card>
        </MDBox>
        <Footer/>
      </DashboardLayout>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <DashboardLayout>
        <MDBox py={3}>
          You must be logged in to access this page, redirecting you to the login page...
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default Home;
