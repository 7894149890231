import React, {useEffect, useRef, useState} from "react"
import VideoCard from './VideoCard'
import Container from './Container'
import Api from '../Api/Api'
import Cookies from 'universal-cookie'
import PassGate from './PassGate'
import {useUserController} from "../context/user";
import PropTypes from "prop-types";
import {useSearchParams} from 'react-router-dom';
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import MDBox from "../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../components/MDTypography";
import Footer from "../components/Footer";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import {useMaterialUIController} from "../context";

const cookie = new Cookies();

const CardView = () => {
  const [videos, setVideos] = useState(false);
  const [cat, setCat] = useState(false);
  const fetchRef = useRef(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [controller, dispatch] = useMaterialUIController();
  const {spanish} = controller;
  const [userController] = useUserController();
  const {user} = userController;
  const [userData, setUserData] = useState([])
  const [cards, setCards] = useState([])
  const [filteredCards, setFilteredCards] = useState([])
  // const cat = videos.find(v => v.id === id)
  const getVideos = () => {
    Api.getAllCats().then(data => {
      setVideos(data.data);
      const cat = data.data.find(v => v.id === id);
      setCat(cat)
      fetchRef.current = true;
    }).catch(err => {
      console.log(err);
      setVideos([])
    })
  }
  if (!videos && !fetchRef.current) getVideos()

  useEffect(() => {
    setUserData(cookie.get('meUserData'))
  }, [])

  useEffect(() => {
    if (videos) fetchQuizzes();

  }, [id, videos])

  const fetchQuizzes = () => {
    var qzs = [];
    Api.getAllQuizzes().then(data => {
      data.data.forEach(d => {
        d.cat == id && qzs.push(d)
      })
      if(id === "26cf6bfe-f578-4a32-b01d-b53236b0e153") {
        const sortedQ = qzs.sort((a, b) => {
          return a.english.title.localeCompare(b.english.title, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        })
        qzs = sortedQ
        // console.log(sortedQ, "SORETED?")
      }
      // console.log(id, qzs)
      renderVideoCards(qzs)
    });

  }

  const renderVideoCards = (o) => {

    let c = []
    // let qd = userData.quizData
    let qd = []
    for (var i = 0; i < o.length; i++) {
      var ob = o[i];
      // console.log(ob, "OB")
      ob.s = qd && qd[ob.id]
      c.push(
        <VideoCard className="single-card-body" data={ob} key={i} num={i}/>
      )
    }
    // setCards([])
    setCards(c)
    setFilteredCards(c)
  }

  const filterCards = (e) => {
    if(e.target.value.length > 0) {
      const fCards = cards.filter(c => {
        // console.log( c.props.data.english.title.toLowerCase(), e.target.value.toLowerCase(), c.props.data.english.title.toLowerCase().includes(e.target.value.toLowerCase()))
        if(c.props.data.english.title.toLowerCase().includes(e.target.value.toLowerCase())) return c
      })
      setFilteredCards(fCards)
    } else setFilteredCards(cards)

  }

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox py={0}>
        <Card>
          <MDBox p={3} lineHeight={1} height="80vh" style={{overflow: 'scroll'}}>
            <PassGate pass={cat.password} gate={cat.title} title={spanish ? cat.spanish : cat.title}>
              <MDTypography variant="h5">{spanish ? cat.spanish : cat.title}</MDTypography>
              <input
                style={{
                  transition: 'box-shadow 0.2s ease 0s',
                  padding: 8,
                  border: '1px solid rgba(0, 22, 66, 0.32)',
                  background: 'rgb(246, 249, 250)',
                  outline: 'none',
                  marginTop: 15,
                  marginBottom: 20,
                  borderRadius: 2
                }}
                autoComplete="off"
                placeholder="Search"
                onChange={e => filterCards(e)}
                // value={filterText}
              />
              <Container spacing>

                {filteredCards}
              </Container>
            </PassGate>
          </MDBox>
        </Card>
      </MDBox>
      <Footer/>
    </DashboardLayout>
  )

}

CardView.propTypes = {
  data: PropTypes.any,
};

export default CardView;
