/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
import React, { useRef, useState } from "react";
import "flatpickr/dist/flatpickr.css";
// Material React components
import MDTypography from "components/MDTypography";

// Material React example components

import { Paper, IconButton, Icon } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PDF from "../../../assets/images/PDF.png";

// eslint-disable-next-line
import styles from "./styles";
import azure from "../../../utils/azure";

const File = ({ file, canDelete, refresh}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const {deleteSafetyDoc} = azure;

  return (
    <Paper style={styles.fileCard} elevation={1}>
      {isDownloading ? (
        <div style={styles.buttonTag}>
          <MDTypography variant="h6">
            <p>Downloading...</p>
          </MDTypography>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <div style={styles.buttonTag}>
            <div style={styles.buttonTagTitle}>
              <img src={PDF} style={{height: 50, marginRight: 15}} />
              <MDTypography variant="h6" style={{ marginRight: 8 }}>
                {file.fileName || file.name}
              </MDTypography>
            </div>
            <div style={styles.buttonTagTitle}>
              <IconButton
                style={{ padding: 4 }}
                onClick={(e) => window.open(file.url, '_blank')}
              >
                <Icon fontSize="medium" color="primary">
                  download_for_offline
                </Icon>
              </IconButton>
              {canDelete && <IconButton
                color="primary"
                style={{padding: 4}}
                onClick={(e) => {
                  deleteSafetyDoc(file.name);
                  refresh()
                }}
              >
                <Icon fontSize="medium" style={{color: '#D43D32'}}>
                  delete
                </Icon>
              </IconButton>}
            </div>
          </div>
        </>
      )}
    </Paper>
  );
};
export default File;
