import React, {Component} from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from "prop-types";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.count,
      collapsed: false,
      type: '',
      answer: null,
      english: {
        title: '',
        options: [],
      },
      spanish: {
        title: '',
        options: [],
      }
    }
  }

  componentDidMount() {
    this.props.data && this.buildQuestion(this.props.data)
  }

  buildQuestion = (o) => {
    this.setState({id: o.id, type: o.type, answer: o.answer, english: o.english, spanish: o.spanish})
  }

  handleChange = (e, t, l) => {
    if (l) {
      let lang = this.state[l]
      lang[t] = e.target.value
      this.setState({[l]: lang}, () => {
        this.setData()
      })
    } else {
      this.setState({[t]: e.target.value}, () => {
        this.setData()
      })
    }
  }

  addOption = () => {
    let english = this.state.english;
    let spanish = this.state.spanish;
    let engOption = {id: english.options.length, title: ''}
    let spanOption = {id: spanish.options.length, title: ''}
    english.options.push(engOption)
    spanish.options.push(spanOption)

    this.setState({english: english, spanish: spanish}, () => {
      this.setData()
    })
  }

  delOption = (id) => {
    let eng = this.state.english;
    let span = this.state.spanish;
    eng.options.forEach((o, i) => {
      if (o.id === id) {
        eng.options[i].isDeleted = true
        span.options[i].isDeleted = true
      }
    })

    this.setState({english: eng, spanish: span}, () => {
      this.setData()
    })
  }

  setAnswer = (e) => {
    let val = e.target.value;
    this.setState({answer: val}, () => {
      this.setData();
    })
  }

  buildOptions() {
    let eng = this.state.english.options;
    let span = this.state.spanish.options;

    let fullOpts = [];
    let count = 0;
    eng.forEach((o, i) => {
      !o.isDeleted && count++
      !o.isDeleted && fullOpts.push(
        <Grid item xs={12} key={i}>
          <Grid container spacing={2} alignItems="center">
            {/* <Grid item xs={.0}></Grid> */}
            <Grid item xs={.52}>
              <FormControlLabel key={i} value={i} control={<Radio checked={this.state.answer == i}/>}
                                onChange={(e) => {
                                  this.setAnswer(e)
                                }} label=""/>
            </Grid>
            <Grid item xs={5.4}>
              <TextField fullWidth color="secondary" data-id={i} label={"Option " + (count)}
                         value={o.title} onChange={(e) => this.handleOptionChange(e)}/>
            </Grid>
            <Grid item xs={5.4}>
              <TextField fullWidth color="secondary" data-id={i}
                         label={"Option " + (count) + " (Spanish)"} value={span[i].title}
                         onChange={(e) => this.handleSpanOptionChange(e)}/>
            </Grid>
            <Grid item xs={.6}>
              <Button onClick={() => {
                this.delOption(i)
              }} style={{
                padding: '8px',
                maxWidth: '20px',
                minWidth: '20px',
                maxHeight: '20px',
                marginTop: '-8px',
                marginRight: '8px'
              }}>
                <DeleteIcon/>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    })

    return fullOpts;
  }

  handleOptionChange = (e, l) => {
    let eng = this.state.english
    let which = e.target.parentElement.parentElement.attributes['data-id'].value
    eng.options[which].title = e.target.value
    this.setState({english: eng}, () => {
      this.setData()
    })

  }

  handleSpanOptionChange = (e) => {
    let span = this.state.spanish
    let which = e.target.parentElement.parentElement.attributes['data-id'].value
    span.options[which].title = e.target.value

    this.setState({spanish: span}, () => {
      this.setData()
    })
  }

  changeAccordian = () => {
    let collapsed = this.state.collapsed
    this.setState({collapsed: !collapsed})
  }

  setData = () => {
    let st = this.state;
    this.props.cb(st)
  }

  render() {
    return (
      <Grid key={this.props.count} item xs={12} style={{paddingTop: '0px'}}>
        <Accordion expanded={this.state.collapsed} style={{
          boxShadow: "none",
          background: 'none',
          borderBottom: 'solid 1px #ccc',
          borderRadius: '0'
        }}>
          <AccordionSummary className="questionExpand"
                            expandIcon={<ExpandMoreIcon onClick={this.changeAccordian}/>}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1} display="flex" justifyContent="center"
                    style={{textAlign: 'right', marginTop: '8px', paddingtop: '0px'}}>
                <h3 style={{margin: 0}}>{this.props.count}.</h3>
              </Grid>
              <Grid item xs={3.8} style={{textAlign: 'right', paddingtop: '8px'}}>
                <TextField fullWidth color="secondary" label="Question Title"
                           value={this.state.english.title} onChange={(e) => {
                  this.handleChange(e, 'title', 'english')
                }}/>
              </Grid>
              <Grid item xs={3.8} style={{textAlign: 'right', paddingtop: '8px'}}>
                <TextField fullWidth color="secondary" label="Question Title (Spanish)"
                           value={this.state.spanish.title} onChange={(e) => {
                  this.handleChange(e, 'title', 'spanish')
                }}/>
              </Grid>
              <Grid item xs={1.5} style={{paddingtop: '8px'}}>
                <FormControl fullWidth>
                  <InputLabel>Question Type</InputLabel>
                  <Select
                    value={this.state.type}
                    label="Question Type"
                    onChange={(e) => {
                      this.handleChange(e, 'type')
                    }}
                  >
                    <MenuItem value={'mc'}>Multiple Choice</MenuItem>
                    <MenuItem value={'tf'}>True / False</MenuItem>
                    {/* <MenuItem value={'sa'}>Short Answer</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={.8} style={{textAlign: 'right', paddingtop: '8px'}}>
                                <TextField fullWidth color="secondary" label="Points" value={this.state.points} onChange={(e) => {this.handleChange('points', e)}}/>
                            </Grid> */}
              <Grid item xs={.6} alignItems="center">
                <DeleteIcon onClick={() => {
                  this.props.del(this.props.count)
                }}/>
              </Grid>
              <Grid item xs={1} display="flex" justifyContent="center"
                    style={{fontSize: '.8rem', color: '#aaa', paddingtop: '8px', marginLeft: 8, textAlign: 'right'}}>
                Click to {this.state.collapsed ? 'hide' : 'show'} answer choices.
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {this.state.type === 'mc' ?
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Grid style={{textAlign: 'right'}} item xs={.3}>
                    <Button onClick={this.addOption} variant="contained" style={{
                      marginTop: '5px',
                      marginBottom: '25px',
                      // width: '30px',
                      minWidth: '100px',
                      // height: '30px',
                      // minHeight: '30px',
                      padding: '5px',
                      // borderRadius: '50%',
                      marginRight: '30px'
                    }}>Add Option +</Button>
                  </Grid>
                  {/*<Grid item xs={.2}></Grid>*/}
                  <Grid item xs={11.2}>
                    <Grid container spacing={2} alignItems="center">
                      {this.buildOptions()}
                    </Grid>
                  </Grid>
                </div>
                :
                <>
                  <Grid item xs={8}>
                    <Grid container alignItems="center">
                      <Grid item xs={2}>
                        <Typography>True</Typography>
                      </Grid>
                      <Grid item xs={1.5}>
                        <FormControlLabel value={0}
                                          control={<Radio checked={this.state.answer == 0}/>}
                                          onChange={(e) => {
                                            this.setAnswer(e)
                                          }} label=""/>
                      </Grid>
                      <Grid item xs={2} justifyContent="center" align="center">
                        <Typography>False</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <FormControlLabel value={1}
                                          control={<Radio checked={this.state.answer == 1}/>}
                                          onChange={(e) => {
                                            this.setAnswer(e)
                                          }} label=""/>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }
}

Question.propTypes = {
  count: PropTypes.any,
  data: PropTypes.any,
  cb: PropTypes.any,
  del: PropTypes.any,
};


export default Question;
