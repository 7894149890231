/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, {useEffect, useState} from "react";

// react-router components
import {useLocation, useNavigate} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material React components
import MDBox from "components/MDBox";

// Material React example components
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import routes from "../../../routes";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "components/Navbars/DashboardNavbar/styles";

// Material React context
import {setMiniSidenav, setOpenConfigurator, setSpanish, setTransparentNavbar, useMaterialUIController} from "context";
import {logoutUser, useUserController} from "context/user";
import {Button, TextField} from "@mui/material";
import language from "../../../assets/data/language";
import profile_img from "../../../assets/images/profile.png";
import azure from "../../../utils/azure";
import styles from "../../../layouts/views/profile/styles";

function DashboardNavbar({absolute, light, isMini}) {
  const navigate = useNavigate();
  const [groupCode, setGroupCode] = useState("");

  const [userController, userDispatch] = useUserController();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode, spanish} = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [profileFile, setProfileFile] = useState({preview: false});
  const route = useLocation().pathname.split("/").slice(1);
  const routeName = routes.filter(r => r.key == route[route.length - 1]);
  // console.log(route, routes, routeName, "NAME")
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => {
    setOpenMenu(false)
  };

  const handleLogout = () => {
    logoutUser(userDispatch);
    // navigate('/sign-in', { replace: true });
  };

  const handleSpanish = () => {
    setSpanish(dispatch, !spanish)

  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{mt: 2}}
    >
      <NotificationItem
        title={userController.user ? (`${userController.user.FirstName} ${userController.user.LastName}`) : "User"}
        onClick={handleCloseMenu}
      />
      <NotificationItem icon={<Icon>logout</Icon>} title={spanish ? 'Cerrar Sesión' : 'Logout'} onClick={handleLogout}/>
      <NotificationItem icon={<Icon>language</Icon>} title={spanish ? "English" : "Español"} onClick={handleSpanish}/>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const {fetchImage} = azure;
  const fetchImageControl = React.useRef(false);
  useEffect(() => {
    if(userController.user.id && !fetchImageControl.current) fetchImage(userController.user.id).then(img => {
      if (img) setProfileFile({preview: img})
      fetchImageControl.current = true;
    }).catch(err => console.log(err, "FETCHING PROFILE IMAGE"))
  }, [userController.user])

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
          <Breadcrumbs icon="home" title={routeName[0] && routeName[0].name ? routeName[0].name : ""} route={route}
                       light={light}/>
        </MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
            <MDBox>
              <div style={{
                backgroundColor: 'white',
                // margin: 8,
                padding: 10,
                borderRadius: 8,
                display: "flex",
                flexDirection: 'row',
                marginRight: 10,
              }}>
                <TextField
                  color="secondary"
                  label={language.common.groupCode(spanish)}
                  value={groupCode}
                  onChange={(e) => {
                    setGroupCode(e.target.value)
                  }}/>
                <Button
                  variant="contained"
                  onClick={(e) => groupCode !== "" && navigate("/quiz/?gcid="+groupCode)}
                  style={{marginLeft: 5}}
                >{language.common.use(spanish)}</Button>

              </div>
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="medium"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                {/* <MDBadge badgeContent={9} color="error" size="xs" circular> */}
                {profileFile.preview ?
                  <div style={{
                    position: 'relative',
                    width: 50,
                    border: "2px solid white",
                    boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
                    height: 50,
                    overflow: 'hidden',
                    borderRadius: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${profileFile.preview})`
                  }}/>
                  : <Icon fontSize="medium" sx={iconsStyle}>account_circle</Icon>
                }
                {/* </MDBadge> */}
              </IconButton>
              {renderMenu()}
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="large"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu" : "menu_open"}
                </Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
