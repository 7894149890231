import colors from "../../../../colors";
export default {
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  createUser: {
    backgroundColor: colors.Theme.offWhite,
    padding: 10,
    marginBottom: 20,
    width: '100%',
    borderRadius: 10
  },
  btn: {
    backgroundColor: '#D63E33',
    color: 'white',
    border: 'none',
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  container: {
    opacity: 1,
    borderRadius: 4,
    borderBottom: '1px solid black',
    padding: 20,
    marginBottom: 0,
    overflow: "scroll",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  },
  expandable: {
    padding: 20,
    background: '#f9f9f9',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
    // marginBottom: 20,
    // maxWidth: '40%'
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    // maxWidth: '40%'
  },
  upload: {
    border: '1px solid rgba(0, 22, 66, 0.32)',
    backgroundColor: '#c7c7c7',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

  },
  img: {
    height: 50
  },
  input: {
    transition: 'box-shadow 0.2s ease 0s',
    // width: '100%',
    marginRight: 10,
    padding: 8,
    border: '1px solid rgba(0, 22, 66, 0.32)',
    background: 'rgb(246, 249, 250)',
    outline: 'none',
    maxWidth: '100%',
    borderRadius: 2
  },
}
