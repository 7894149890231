// react-router-dom components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import {Breadcrumbs as MuiBreadcrumbs} from "@mui/material";
import Icon from "@mui/material/Icon";

// Material React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import typography from "assets/theme/base/typography";

function Breadcrumbs({icon, title, route, light}) {
  const routes = route.slice(0, -1);
  // const { size } = typography;

  return (
    <MDBox mr={{xs: 0, xl: 8}}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({palette: {white, grey}}) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{lineHeight: 0}}
          >
            <Icon fontSize="medium">{icon}</Icon>
          </MDTypography>
        </Link>
        {/*{routes.map((el) => (*/}
        {/*  <Link to={`/${el}`} key={el}>*/}
        {/*    <MDTypography*/}
        {/*      component="span"*/}
        {/*      variant="button"*/}
        {/*      fontWeight="regular"*/}
        {/*      textTransform="capitalize"*/}
        {/*      color={light ? "white" : "dark"}*/}
        {/*      opacity={light ? 0.8 : 0.5}*/}
        {/*      sx={{lineHeight: 0, fontSize: "1.125rem"}}*/}
        {/*    >*/}
        {/*      {el}*/}
        {/*    </MDTypography>*/}
        {/*  </Link>*/}
        {/*))}*/}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{lineHeight: 0, fontSize: "1.125rem"}}
        >
          {title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs>
      {/* <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </MDTypography> */}
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
