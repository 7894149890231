import React, {useEffect} from 'react';
import PropTypes from "prop-types";

const systemIdTag = 'responsive_style_event__';
const ResponsiveContext = React.createContext();
const ResponsiveContextDispatch = React.createContext();
const ResponsiveDeviceContext = React.createContext();

const styleParser = (device, styleInput) => {
  const guide = {
    tablet: ['tablet'],
    desktop: ['tablet', 'desktop'],
    xlScreen: ['tablet', 'desktop', 'xlScreen']
  }
  const baseStyles = styleInput[0];
  const dynamicStyles = styleInput[1];
  const isMultiRuled = dynamicStyles.tablet || dynamicStyles.desktop || dynamicStyles.xlScreen;
  let styles = {...baseStyles};
  const nonMobileTags = guide[device];
  if (device === 'mobile') {
    return styles;
  }
  if (isMultiRuled) {
    nonMobileTags.forEach(tag => Object.assign(styles, dynamicStyles[tag]));
    return styles;
  }
  return {...baseStyles, ...dynamicStyles}
}


const calcScreenSize = (width) => {

  const size = width < 768 ? 'mobile'
    : width < 1024 ? 'tablet'
      : width < 3000 ? 'desktop'
        : 'xlScreen';

  const columns = [
    ['oneHalf', 100, 50],
    ['oneThird', 100, 33.3333],
    ['oneThirdFlex', 50, 33.3333],
    ['twoThirds', 100, 66.6666],
    ['oneFourth', 100, 25],
    ['oneFourthFlex', 50, 25],
    ['threeFourths', 100, 75],
    ['oneFifth', 100, 20],
    ['oneFifthFlex', 33.3333, 20],
    ['twoFifths', 100, 40],
    ['threeFifths', 100, 60],
    ['fourFifths', 100, 80],
  ];
  const retCols = {};
  columns.forEach((col) => {
    const width = size === 'mobile' ? col[1] : col[2];
    retCols[col[0]] = {
      width: `${width}%`,
      float: 'left',
      padding: width === 100 ? 0 : '0 10px',
    }
  });

  return {
    size,
    columns: retCols,
  };
};

const reducer = (state, action) => {
  console.log('ResponsiveContext ---> ' + (action.type === undefined ? 'default' : action.type));
  switch (action.type) {
    case 'add_resize_event':
      return {
        ...state,
        resizeEvents: {
          ...state.resizeEvents,
          [action.eventID]: action.event,
        },
      };
    case 'remove_resize_event':
      const resizeEvents = {...state.resizeEvents};
      delete resizeEvents[action.eventID];
      return {
        ...state,
        resizeEvents,
      };
    case 'add_responsive_style':
      let newEvents = {...state.styleEvents};
      let newStyles = {...state.responsiveStyles}
      if (typeof action.id === 'object') {
        Object.keys(action.id).forEach(id => {
          const systemID = systemIdTag + id;
          const itemStyles = action.id[id];
          newEvents[systemID] = (size) => styleParser(size, itemStyles);
          newStyles[id] = styleParser(state.size, itemStyles);
        })
      } else {
        const systemID = systemIdTag + action.id;
        newEvents[systemID] = (size) => styleParser(size, action.styles);
        newStyles[action.id] = styleParser(state.size, action.styles);
      }
      return {
        ...state,
        styleEvents: newEvents,
        responsiveStyles: newStyles,
      };
    case 'remove_responsive_style':
      const styleEvents = {...state.styleEvents};
      if (Array.isArray(action.id)) {
        action.id.forEach(id => delete styleEvents[systemIdTag + action.id])
      } else {
        delete styleEvents[systemIdTag + action.id];
      }
      return {
        ...state,
        styleEvents,
      };
    default:
      const calculation = calcScreenSize(action.width);
      let styleUpdates = {...state.responsiveStyles};
      // resize event handlers
      const resizeHandlers = Object.keys(state.resizeEvents);
      const styleHandlers = Object.keys(state.styleEvents);
      if (resizeHandlers && resizeHandlers.length) resizeHandlers.forEach((event) => state.resizeEvents[event]());
      // responsive styles update on device change
      if (styleHandlers && state.size !== calculation.size) {
        styleHandlers.forEach((styleId) => {
          styleUpdates[styleId.replace(systemIdTag, '')] = state.styleEvents[styleId](calculation.size);
        })
      }
      return {
        ...state,
        size: calculation.size,
        columns: calculation.columns,
        responsiveStyles: styleUpdates,
      };
  }
};

const ResponsiveContextProvider = (props) => {
  // state
  const [state, dispatch] = React.useReducer(reducer, {
    size: calcScreenSize(document.body.clientWidth).size,
    columns: calcScreenSize(document.body.clientWidth).columns,
    resizeEvents: {},
    styleEvents: {},
    responsiveStyles: {},
  });

  // mount/unmount resize listener
  useEffect(() => {
    const resizeFunc = () => dispatch({width: document.body.clientWidth});
    window.addEventListener('resize', resizeFunc);
    return (() => window.removeEventListener('resize', resizeFunc));
  }, []);

  // resize event mount/unmount
  const addResizeEvent = React.useCallback((id, event) => dispatch({
    type: 'add_resize_event',
    event,
    eventID: id,
  }), []);
  const removeResizeEvent = React.useCallback((id) => dispatch({
    type: 'remove_resize_event',
    eventID: id,
  }), []);

  const responsiveStyleKeys = Object.keys(state.responsiveStyles);
  const styleKeyRef = React.useRef(responsiveStyleKeys);

  // responsive style event mount
  const addStyle = React.useCallback((id, styleInput) => {
    let shouldAdd = typeof id !== 'object'
    if (!shouldAdd) {
      Object.keys(id).forEach(key => {
        if (!styleKeyRef.current.includes(key)) shouldAdd = true
      })
    } else shouldAdd = !styleKeyRef.current.includes(id);
    if (shouldAdd) {
      dispatch({type: 'add_responsive_style', id: id, styles: styleInput});
      if (typeof id === 'object') {
        styleKeyRef.current = styleKeyRef.current.concat(Object.keys(id));
      } else styleKeyRef.current.push(id);
    }
  }, [])

  // responsive style event unmount
  const removeStyle = React.useCallback(id => {
    let shouldRemove = !Array.isArray(id)
    if (!shouldRemove) {
      id.forEach(key => {
        if (styleKeyRef.current.includes(key)) shouldRemove = true
      })
    } else shouldRemove = styleKeyRef.current.includes(id);
    if (shouldRemove) {
      dispatch({type: 'remove_responsive_style', id: id})
      styleKeyRef.current = styleKeyRef.current.filter(keyID => Array.isArray(id) ? !id.includes(keyID) : keyID !== id);
    }
  }, [])

  const valuePayload = React.useMemo(() => ({
    device: state.size,
    columns: state.columns,
    rspStyles: state.responsiveStyles,
  }), [state])

  const dispatchPayload = React.useMemo(() => ({
    addResizeEvent,
    removeResizeEvent,
    addStyle,
    removeStyle,
  }), [addResizeEvent, removeResizeEvent, addStyle, removeStyle])

  const devicePayload = React.useMemo(() => state.size, [state.size])

  return (
    <ResponsiveContext.Provider value={valuePayload}>
      <ResponsiveContextDispatch.Provider value={dispatchPayload}>
        <ResponsiveDeviceContext.Provider value={devicePayload}>
          {props.children}
        </ResponsiveDeviceContext.Provider>
      </ResponsiveContextDispatch.Provider>
    </ResponsiveContext.Provider>
  );
};

ResponsiveContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};


const useResponsiveContext = () => {
  const context = React.useContext(ResponsiveContext);
  if (context === undefined) {
    throw new Error('useResponsiveContext must be used within ResponsiveContextProvider')
  }
  return context;
}

const useResponsiveDispatch = () => {
  const context = React.useContext(ResponsiveContextDispatch);
  if (context === undefined) {
    throw new Error('useResponsiveDispatch must be used within ResponsiveContextProvider')
  }
  return context;
}

const useDevice = () => React.useContext(ResponsiveDeviceContext);


export {useDevice, useResponsiveDispatch, useResponsiveContext, ResponsiveContext, ResponsiveContextProvider};
