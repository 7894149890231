import Card from "@mui/material/Card";
import "flatpickr/dist/flatpickr.css";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import {useUserController} from "context/user";
import {useMaterialUIController} from "../../../context";
import styles from "./styles";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import userData from "../../../assets/data/user";
import Api from "../../../Api/Api";
import {FormControl, MenuItem, Select, TextField} from "@mui/material";
import {useDevice} from "../../../context/device";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useSearchParams} from "react-router-dom";
import FileInputButton from "../../../components/FileInputButton";
import dayjs from "dayjs";
import Certificates from "./Certificates";
import azure from "../../../utils/azure";
import language from "../../../assets/data/language";

const profile_img = require('../../../assets/images/profile.png');

const defaultForm = [
  {
    label: 'Job Classification',
    key: 'JobClassification',
    type: 'dropdown',
    options: userData.classification
  },
  {
    label: 'Email',
    key: 'Email',
    type: 'text',
    disabled: true
  },
  {
    label: 'Username',
    key: 'Username',
    type: 'text',
    min: 6
  },
  {
    label: 'First Name',
    key: 'FirstName',
    type: 'text'
  },
  {
    label: 'Last Name',
    key: 'LastName',
    type: 'text'
  },
  {
    label: 'Role',
    key: 'RoleID',
    type: 'text'
  },
  {
    label: 'Phone Number',
    key: 'PhoneNumber',
    type: 'text'
  },
  {
    label: 'Date Of Hire',
    key: 'DateOfHire',
    type: 'calendar'
  },
  {
    label: 'Job Name',
    key: 'JobName',
    type: 'text'
  },
  {
    label: 'Home Address',
    key: 'HomeAddress',
    type: 'text'
  },
  {
    label: 'Company',
    key: 'CompanyID',
    type: 'dropdown',
    options: userData.companyId
  }
];

const FormInput = ({label, type, value, onChange, required, side, options, disabled, tag, spanish}) => {
  return (
    <label id={label} htmlFor={label} style={side ? styles.form.labelSide : styles.form.label}>
          <span style={styles.form.labelSpan}>
              {label}
            {required && <span style={{color: 'red'}}> *</span>}
          </span>

      {
        type === "calendar" && <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker onChange={(e) => onChange(e, tag)} value={dayjs(value)}/>
        </LocalizationProvider>
      }
      {type === "text" &&
        <input
        style={disabled ? styles.form.disabledInput : styles.form.input}
        type={type}
        value={value}
        onChange={(e) => onChange(e, tag)}
        required={required}
      />
        // <TextField fullWidth color="secondary" label={label} value={value}
        //            onChange={(e) => onChange(e,tag)}/>
      }
      {(options && value !== undefined) && <FormControl>
        <Select
          // style={styles.form.input}
          label={label}
          value={value}
          onChange={(e) => onChange(e, tag)}
        >
          {options.map((id, i) => <MenuItem key={i} style={{height: 50, fontSize: '1.2rem'}} value={id}>{id}</MenuItem>)}
        </Select>
      </FormControl>
      }
    </label>
  )
};
FormInput.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
  tag: PropTypes.string.isRequired,
  required: PropTypes.bool,
  options: PropTypes.array,
  side: PropTypes.bool,
  disabled: PropTypes.bool,
  spanish: PropTypes.bool
};

const FormComponent = ({input, device, onChange, spanish}) => {
  const label = language.profile[input.key](spanish)
  return <div style={device === "mobile" ? styles.form.containerMobile : styles.form.container}>
    {(["dropdown", "text", "calendar"].includes(input.type)) &&
      <FormInput
        label={label || input.label}
        type={input.type}
        tag={input.key}
        value={input.value}
        options={input.options}
        disabled={input.disabled}
        onChange={onChange}
        spanish={spanish}
      />
    }
  </div>
};

FormComponent.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  input: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  device: PropTypes.string.isRequired,
  spanish: PropTypes.bool
}

function Profile() {
  const [userController] = useUserController();
  const {user} = userController;
  const [controller, dispatch] = useMaterialUIController();
  const {spanish} = controller;
  const [profileData, setProfileData] = useState(false);
  const [profileFile, setProfileFile] = useState({preview: profile_img});
  const [profileImageMsg, setProfileImageMsg] = useState(false);
  const [error, setError] = useState(false);
  const fetchController = useRef(false);
  const [form, setForm] = useState(defaultForm);
  const [editProfile, setEditProfile] = useState(false);
  const device = useDevice();
  const [searchParams] = useSearchParams();

  const {fetchImage, uploadImage} = azure;
  const email_route = searchParams.get("email");

  if (!profileData && !fetchController.current) {
    let email_to_fetch = user.email;
    if (user.Admin && email_route) email_to_fetch = email_route
    Api.get_profile(email_to_fetch).then(profile => {
      if (profile && profile.data) {
        fetchController.current = true;
        let updatedForm = [...form];
        updatedForm = updatedForm.map(i => ({
          ...i,
          value: profile.data[i.key]
        }));
        setForm(updatedForm);
        setProfileData(profile.data);
        fetchImage(profile.data.UserID).then(img => {
          if (img) setProfileFile({preview: img})
        }).catch(err => console.log(err, "FETCHING PROFILE IMAGE"))
      } else {
        setError(true)
      }
    }).catch(err => {
      console.log(err);
      setError(true)
    })
  }
  useEffect(() => {
    setProfileData(false);
    fetchController.current = false;
  },[email_route])

  const saveProfile = (e) => {
    e.preventDefault();
    Api.save_profile(profileData).then(saved => {
      console.log("SAVED PROFILE UPDATED")
    }).catch(err => console.log(err));

    if (profileFile.data) {
      console.log(profileFile, "NEW")
      uploadImage(profileFile.data, profileFile.file_name).then(saved => console.log(saved)).catch(err => console.log(err));
    }

    setEditProfile(false)
  }

  const onChange = (e, key) => {
    let value;
    if (e.target) value = e.target.value;
    else if (e.$d) value = e.$d;

    let newForm = form.map(f => {
      if (f.key === key) return {...f, value: value}
      else return f
    });
    let newProfile = {...profileData, [key]: value};
    setProfileData(newProfile)
    setForm(newForm);
  }

  const getValue = (obj, v) => {
    try {
      if (obj.type == "calendar" && v) return dayjs(v).format('MM-DD-YYYY') || v
      else return v
    } catch (e) {
      console.log(e, "E")
      return null
    }
  }

  // console.log(profileFile)
  const handleProfileImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file && ["image/jpeg"].includes(file.type)) {
      if (file.size > 1000000) setProfileImageMsg(language.common.fileLarge(spanish));
      else {
        let file_name = profileData.UserID.toString() + '.' + file.name.split('.')[1];
        const img = {
          file_name,
          preview: URL.createObjectURL(file),
          data: file
        }
        setProfileFile(img);
      }
    } else setProfileImageMsg(language.profile.fileWrong(spanish))

  }


  if (userController.user) {
    return (
      <DashboardLayout>
        <DashboardNavbar/>
        <MDBox py={0}>
          <Card>
            <MDBox p={3} lineHeight={1} height="80vh" style={{overflow: 'scroll'}}>
              <div style={styles.profileContainer}>
                <div style={styles.form.buttonContainer}>
                  <button
                    onClick={editProfile ? saveProfile : () => setEditProfile(true)}
                    style={styles.form.button}
                  >{editProfile ? language.profile.save(spanish) : language.profile.update(spanish)}</button>
                  {editProfile && <button
                    onClick={() => setEditProfile(false)}
                    style={styles.form.button}
                  >{language.profile.cancel(spanish)}</button>}
                </div>
                {error && <p>{language.common.failed(spanish)}</p>}
                {!editProfile && <>
                  <div style={styles.imageContainer}>
                    <div style={{...styles.imageWrap, backgroundImage: `url(${profileFile.preview})`}}/>
                  </div>
                  <div style={device === "mobile" ? styles.profileDataMobile : styles.profileData}>
                    {form.map((input, i) => <div style={device === "mobile" ? styles.profileItemMobile : styles.profileItem} key={i}>
                      <p style={{fontWeight: 'bold', marginRight: 5}}>{language.profile[input.key](spanish)}:</p>
                      <p>{getValue(input, input.value)}</p>
                    </div>)}
                  </div>
                </>}
                {editProfile && <>
                  {form && <>
                    <div style={styles.imageContainer}>
                      <div style={{...styles.imageWrap, backgroundImage: `url(${profileFile.preview})`}}/>
                      <FileInputButton onChange={handleProfileImage} name={language.profile.replace(spanish)}
                                       styles={styles.form.imageUploadBtn}/>
                      {profileImageMsg && <p style={{fontSize: '1rem'}}>{profileImageMsg}</p>}
                    </div>
                    <div style={styles.formContainer}>
                      {form.map((input, i) => <FormComponent
                        key={i}
                        device={device}
                        input={input}
                        onChange={onChange}
                        spanish={spanish}
                      />)}
                    </div>
                  </>}
                </>}
              </div>
              {profileData && <div>
                <Certificates user={profileData} spanish={spanish}/>
              </div>}

            </MDBox>
          </Card>
        </MDBox>
        <Footer/>
      </DashboardLayout>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <DashboardLayout>
        <MDBox py={3}>
          You must be logged in to access this page, redirecting you to the login page...
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default Profile;
