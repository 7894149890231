export default {
  language: (spanish) => spanish ? "English" : "Español",
  auth: {
    forgot: (spanish) => spanish ? '¿Has olvidado tu contraseña?' : 'Forgot password?',
    reset: (spanish) => spanish ? 'Restablecer Contraseña' : 'Reset Password',
    login: (spanish) => spanish ? 'Bienvenido' : 'Log In',
    signup: (spanish) => spanish ? 'Crear Cuenta' : 'Sign Up',
    noaccount: (spanish) => spanish ? '¿No estas registrado?' : 'Don\'t have an account?',
    account: (spanish) => spanish ? '¿Ya estas registrado?' : 'Already have an account?',
    code: (spanish) => spanish ? 'Enviar Codigo' : 'Send Code',
    submit: (spanish) => spanish ? 'Enviar' : 'Submit',
    nomatch: (spanish) => spanish ? 'Las contraseñas proporcionadas no coinciden!' : 'Passwords do not match!',
    confirmP: (spanish) => spanish ? 'Confirmar contraseña' : 'Confirm Password',
    password: (spanish) => spanish ? 'Contraseña' : 'Password',
    email: (spanish) => spanish ? 'Correo Electrónico' : 'Email',
    fName: (spanish) => spanish ? 'Nombres' : 'First Name',
    lName: (spanish) => spanish ? 'Apellidos' : 'Last Name',
    passwordRequirement: (spanish) => spanish ? 'Debe tener 8 o más caracteres y contener al menos 1 número, 1 carácter especial y 1 letra mayúscula' : 'Must be 8 or more characters and contain at least 1 number, 1 special character, and 1 capital letter',
    classification: (spanish) => spanish ? 'Clasificacion' : 'Classification',
    classificationJob: (spanish) => spanish ? 'Clasificacion de Trabajo' : 'Job Classification',
    company: (spanish) => spanish ? 'Identificación de la Compañia' : 'Company Identification',
    verifyCode: (spanish) => spanish ? 'Código de verificación' : 'Verification Code',
    verifyMsg: (spanish) => spanish ? 'Si te has registrado previamente con este correo electrónico, deberías recibir un correo electrónico con el código de verificación.' : 'If you have previously registered with this email, you should receive an email with the verification code.',
    failed: (spanish) => spanish ? 'Algo falló. Por favor, inténtelo de nuevo más tarde' : 'Something Failed. Please Try Again Later',
  },
  profile: {
    // Fields
    Email: (spanish) => spanish ? 'Correo Electrónico' : 'Email',
    FirstName: (spanish) => spanish ? 'Nombre' : 'First Name',
    LastName: (spanish) => spanish ? 'Apellido' : 'Last Name',
    Username: (spanish) => spanish ? 'Usuario' : 'Username',
    RoleID: (spanish) => spanish ? 'Role' : 'Role',
    PhoneNumber: (spanish) => spanish ? 'Numero' : 'Phone Number',
    DateOfHire: (spanish) => spanish ? 'Fecha de Contratación' : 'Date of Hire',
    JobName: (spanish) => spanish ? 'Nombre de Trabajo' : 'Job Name',
    HomeAddress: (spanish) => spanish ? 'Dirección de Casa' : 'Home Address',
    JobClassification: (spanish) => spanish ? 'Clasificacion de Trabajo' : 'Job Classification',
    CompanyID: (spanish) => spanish ? 'Identificación de la Compañia' : 'Company Identification',

    //Other
    update: (spanish) => spanish ? 'Actualizar' : 'Update',
    fileWrong: (spanish) => spanish ? 'Solo se permiten archivos JPEG' : 'Only JPEG files are allowed',
    replace: (spanish) => spanish ? 'Reemplazar' : 'Replace',
    save: (spanish) => spanish ? 'Guardar' : 'Save',
    cancel: (spanish) => spanish ? 'Cancelar' : 'Cancel',
  },
  certificates: {
    // Table
    certificates: (spanish) => spanish ? 'Certificados' : 'Certificates',
    name: (spanish) => spanish ? 'Nombre' : 'Name',
    issued: (spanish) => spanish ? 'Fecha' : 'Issued',
    expiration: (spanish) => spanish ? 'Vencimiento' : 'Expiration',
    quiz: (spanish) => spanish ? 'Prueba' : 'Quiz',
    manual: (spanish) => spanish ? 'Cargado Manualmente' : 'Manually Uploaded',
    download: (spanish) => spanish ? 'Descargar' : 'Download',
    delete: (spanish) => spanish ? 'Borrar' : 'Delete',
    sourceQuiz: (spanish) => spanish ? 'Cuestionario de Origen' : 'Source Quiz',
    Annual: (spanish) => spanish ? 'Anual' : 'Annual',
    TwoYears: (spanish) => spanish ? '2 Años' : '2 Years',
    ThreeYears: (spanish) => spanish ? '3 Años' : '3 Years',
    Other: (spanish) => spanish ? 'Otro' : 'Other',
    otherSourceQuiz: (spanish) => spanish ? 'Otro Cuestionario de Origen' : 'Other Source Quiz',
    issuedDate: (spanish) => spanish ? 'Fecha de Emisión' : 'Issued Date',
    addFile: (spanish) => spanish ? 'Agregar Archivo' : 'Add File',
    generate: (spanish) => spanish ? 'Tarjeta de Certificación Digital' : 'Digital Certification Card',
    pending: (spanish) => spanish ? "Pendiente" : "Pending",
    expired: (spanish) => spanish ? "Expirados" : "Expired",
    expiring: (spanish) => spanish ? "Expirando" : "Expiring",
    completed: (spanish) => spanish ? "Completados" : "Completed",

    //Other
    update: (spanish) => spanish ? 'Actualizar' : 'Update',
    replace: (spanish) => spanish ? 'Reemplazar' : 'Replace',
    save: (spanish) => spanish ? 'Guardar' : 'Save',
    cancel: (spanish) => spanish ? 'Cancelar' : 'Cancel',
    fileWrong: (spanish) => spanish ? 'Solo se permiten archivos PDF' : 'Only PDF files are allowed',

  },
  common: {
    fileLarge: (spanish) => spanish ? 'El tamaño del archivo no puede exceder más de 1 MB' : "File size cannot exceed more than 1MB",
    update: (spanish) => spanish ? 'Editar' : 'Update',
    upload: (spanish) => spanish ? 'Subir' : 'Upload',
    safetyDocuments: (spanish) => spanish ? 'Documentos de Seguridad' : 'Safety Documents',
    replace: (spanish) => spanish ? 'Reemplazar' : 'Replace',
    save: (spanish) => spanish ? 'Guardar' : 'Save',
    yes: (spanish) => spanish ? 'Si' : 'Yes',
    download: (spanish) => spanish ? 'Descargar' : 'Download',
    no: (spanish) => spanish ? 'No' : 'No',
    use: (spanish) => spanish ? 'USAR' : 'USE',
    groupCode: (spanish) => spanish ? 'Codigo del grupo' : 'Group Code',
    search: (spanish) => spanish ? 'Buscar' : 'Search',
    cancel: (spanish) => spanish ? 'Cancelar' : 'Cancel',
    failed: (spanish) => spanish ? 'Algo falló. Por favor, inténtelo de nuevo más tarde' : 'Something Failed. Please Try Again Later',

  }
}
