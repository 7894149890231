import colors from "../../../colors"

const button = {
  display: 'block',
  border: 'medium none',
  padding: '12px 16px',
  textAlign: 'center',
  color: 'white',
  cursor: 'pointer',
  outline: 'none',
  minWidth: 152,
  width: 'auto',
  margin: '10px auto 14px'
};
const input = {
  transition: 'box-shadow 0.2s ease 0s',
  width: '100%',
  padding: 8,
  border: '1px solid rgba(0, 22, 66, 0.32)',
  background: 'rgb(246, 249, 250)',
  outline: 'none',
  maxWidth: '100%',
  height: 50,
  borderRadius: 2
}
export default {
  profileContainer: {
    background: '#f2f6f8',
    borderRadius: 4,
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 8px -4px',
    padding: '60px 10px 10px 10px',
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrap: {
    position: 'relative',
    width: 100,
    height: 100,
    overflow: 'hidden',
    borderRadius: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  profileItem: {
    color: colors.Theme.darkGray,
    margin: 20,
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  profileItemMobile: {
    color: colors.Theme.darkGray,
    margin: 10,
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  profileData: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  profileDataMobile: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    labelSpan: {
      marginBottom: 5,
      color: colors.Theme.black,
      fontSize: "1rem"
    },
    container: {
      width: '46%',
      marginRight: '1%',
      marginLeft: '1%',
      marginBottom: 20
    },
    containerMobile: {
      width: '100%',
      marginBottom: 20
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
    },
    labelSide: {
      width: '48%',
    },
    input: {
      ...input,
    },
    disabledInput: {
      ...input,
      cursor: 'not-allowed',
      color: 'grey'
    },
    imageUploadBtn: {
      ...button,
      minWidth: 80,
      fontSize: '1rem',
      borderRadius: 5,
      margin: '10px',
      padding: 5,
      cursor: 'pointer',
      color: colors.Theme.white,
      backgroundColor: colors.Theme.gray
    },
    button: {
      ...button,
      padding: '5px 5px',
      marginLeft: 10,
      minWidth: 80,
      borderRadius: 5,
      width: 'auto',
      background: colors.MARATHON.primary
    },
    buttonContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      top: 5,
      right: 10
    },
    disabledButton: {
      ...button,
      cursor: 'not-allowed',
      backgroundColor: 'grey'
    }
  }
}
