import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import VideoModal from './VideoModal';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import {useUserController} from "../context/user";
import PropTypes from "prop-types";
import {useMaterialUIController} from "../context";

const VideoCard = (props) => {

  const [userController] = useUserController();
  const {user} = userController;
  const [controller, dispatch] = useMaterialUIController();
  const {spanish} = controller;
  const vData = user.vData ? user.vData : null
  const [data, setData] = useState(props.data)
  const [results, setResults] = useState(null)
  const [rewatch, setRewatch] = useState(false)

  useEffect(() => {
    setData(props.data)
    var o = props.data
    var outcome = {p: false};
    if (o.passed) {
      outcome.p = true
    }

    if (o.score > 69) {
      outcome.s = true
    }

    outcome.t = o.taken;
    setResults(outcome)
  }, [])

  useEffect(() => {
    if (vData) {
      if (vData.id === data.id) {
        setRewatch(true)
      }
    }
  }, [])

  const resultsAction = (o) => {
    var outcome = results
    return o.s ? evalresults(outcome, o.s) : !spanish ? 'Not started.' : 'No empezado.'
  }

  const evalresults = (o, s) => {
    return (
      <>
        {s}%&nbsp;
        {s >= 70 ? <CheckCircleRoundedIcon className="progress-icon" color="success"/> :
          <ErrorRoundedIcon className="progress-icon" color="warning"/>}
      </>
    )
  }

  return (
    <Grid className="single-card-body" item xs={12} md={6} lg={4} key={props.num}>
      <Card>
        <CardContent style={{paddingbottom: "16px"}}>
          <Box mb={3} mt={3}>
            <Typography gutterBottom align="center" paddingtop={20} paddingbottom={5}>
              {spanish && data.spanish.title ? data.spanish.title : data.english.title}
            </Typography>
          </Box>
          <Grid item align="center"
                style={{overflowWrap: "break-word", minHeight: 170, overflowY: 'hidden', maxHeight: '250px'}}
                xs={12}>
            {data.english.thumb ?
              <img src={(spanish && data.spanish.thumb !== "") ? data.spanish.thumb : data.english.thumb} width="250px"/> :
              <div style={{minWidth: '250px', minHeight: '250px'}}>&nbsp;</div>}
          </Grid>
          <Grid container>
            <Grid item align="left" xs={6}>
              {/* <Button key="down" variant="contained" onClick={() => {this.props.down(this.props.num)}}>Start</Button> */}
              {results && <VideoModal data={data} rewatch={rewatch} results={results}/>}
            </Grid>
            <Grid item align="right" xs={6} className="video-results-container">
              {results && resultsAction(data)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )

};

VideoCard.propTypes = {
  data: PropTypes.any,
  num: PropTypes.any
};

export default VideoCard;
