/* eslint-disable arrow-body-style */
/**
 This file is used for controlling the global states of the components,
 you can customize the states for the different components here.
 */
import {createContext, useContext, useReducer, useRef} from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

// import { decodeToken } from "utils/helper";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const User = createContext();

// Setting custom name for the context which is visible on react dev tools
User.displayName = "UserContext";

// User reducer
// qDgbxD6hPMtzkfX!
function reducer(state, action) {
  const clearedUser = {isLoggedIn: false, activeQuiz: {}, user: null};
  switch (action.type) {
    case "QUIZ": {
      return {
        ...state,
        activeQuiz: {...state.activeQuiz, ...action.value}
      };
    }
    case "QUIZ_RESET": {
      return {
        ...state,
        activeQuiz: {}
      }
    }
    case "LOGIN": {
      // const userToSave = {
      //   email: action.value.email,
      //   FirstName: "Mateo",
      //   LastName: "Montoya",
      //   CompanyID: 1,
      //   JobClassification: 2
      // }
      const userToSave = action.value;
      let today = new Date();
      today.setHours(today.getHours() + 24);
      cookies.set('user', userToSave, {path: '/', expires: today});
      return {...state, isLoggedIn: true, user: userToSave};
    }
    case "LOGOUT": {
      cookies.remove('user')
      return clearedUser;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// User context provider
function UserControllerProvider({children}) {
  const navigate = useNavigate();
  const initialState = {
    user: null,
    activeQuiz: {},
    isLoggedIn: false,
  };
  const userCookie = cookies.get('user')
  // const [isNaviageToSessionErrPage, setNaviageToSessionErrPage] = useState(false);
  const [controller, dispatch] = useReducer(reducer, initialState);
  // const [menuController, menuDispatch] = useMaterialUIController();
  // const {spanish} = menuController;
  const failed = useRef(false);

  if (
    !controller.isLoggedIn &&
    !userCookie &&
    !failed.current
    && window.location.pathname !== "/auth"
  ) {
    // if(window.location.search && window.location.search.includes('lang=es')) {
    //   console.log('yes spansih')
    //   setSpanish(menuDispatch, true)
    // }
    window.location = `/auth`;
  }

  if(controller.activeQuiz && controller.activeQuiz.id) {
    const path = window.location.pathname.split('/');
    if(path.length !== 3 && (path[1] !== "quiz" || path[1] !== "videos")) {
      dispatch({type: "QUIZ_RESET"})
    }
  }

  // login if token in local storage && is still valid
  if (userCookie && !controller.isLoggedIn) {
    // console.log(userCookie, "user there need to refresh")
    dispatch({
      type: "LOGIN",
      value: userCookie,
    });
  }

  return <User.Provider value={[controller, dispatch]}>{children}</User.Provider>;
}

// React custom hook for using context
function useUserController() {
  const context = useContext(User);

  if (!context) {
    throw new Error("useUserController should be used inside the UserControllerProvider.");
  }

  return context;
}

// Typechecking props for the UserControllerProvider
UserControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const logIn = (dispatch, value) => dispatch({type: "LOGIN", value});
const logoutUser = (dispatch, value) => dispatch({type: "LOGOUT", value});
const registerUser = (dispatch, value) => dispatch({type: "REGISTER", value});
const setActiveQuiz = (dispatch, value) => dispatch({type: "QUIZ", value});
const resetActiveQuiz = (dispatch) => dispatch({type: "QUIZ_RESET"});

export {User, UserControllerProvider, resetActiveQuiz, useUserController, logIn,setActiveQuiz, logoutUser, registerUser};
