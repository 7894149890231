export default {
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  btn: {
    backgroundColor: '#D63E33',
    color: 'white',
    border: 'none',
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  container: {
    opacity: 1,
    borderRadius: 4,
    borderBottom: '1px solid black',
    padding: 20,
    marginBottom: 0,
    overflow: "scroll",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  },
  expandable: {
    padding: 20,
    background: '#f9f9f9',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
    // marginBottom: 20,
    // maxWidth: '40%'
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    // maxWidth: '40%'
  },
  upload: {
    border: '1px solid rgba(0, 22, 66, 0.32)',
    backgroundColor: '#c7c7c7',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

  },
  img: {
    height: 50
  },
  input: {
    transition: 'box-shadow 0.2s ease 0s',
    // width: '100%',
    marginRight: 10,
    padding: 8,
    border: '1px solid rgba(0, 22, 66, 0.32)',
    background: 'rgb(246, 249, 250)',
    outline: 'none',
    maxWidth: '100%',
    borderRadius: 2
  },
  card: {
    container: {
      maxWidth: 500,
      // height: 300,
      border: '1px dotted black',
      padding: 20,
      display:'flex',
      flexDirection: 'column',
      justifyContent:'space-between',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: 22,
      borderRadius: 10
    },
    btn: {
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    img: {
      height: 50
    },
    employee: {
      borderBottom: '1px solid black',
      maxWidth: 380,
      fontSize: '1rem'
    },
    smallText: {
      fontSize: '.85rem'
    },
    table: {
      border: '1px solid black',
      fontSize: '.5rem',
      width: '100%',
      textAlign: 'left',
    }
  },
  notification: {
    section: {
      marginTop: 10,
      marginBottom: 10
    },
    table: {
      border: '1px solid black',
      // marginTop: 10,
      // marginBottom: 30,

      fontSize: '1rem',
      width: '100%',
      textAlign: 'center',
    },
    tableHead: {
      marginBottom: 10,
      marginTop: 20
    },
    tableD: {
      textAlign: 'center'
    }
  }

}
