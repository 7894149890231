/**
 * The base colors for the Soft UI Dashboard PRO Material.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard PRO Material using thie file.
 */
import {shadeColor} from "utils/helper";
import colorsConfig from "colors";
import marathonColors from "../../../colors";

let instanceColors = colorsConfig.MARATHON;
const path = document.location.pathname.split("/");
const urlInstance = path[1];
if (urlInstance && urlInstance in colorsConfig) instanceColors = colorsConfig[urlInstance];
// console.log('instanceColors', instanceColors);

const primaryColor = (instanceColors && instanceColors.primary) || "#381440";
const secondaryColor = (instanceColors && instanceColors.secondary) || "#f68a30";
const lightColor = (instanceColors && instanceColors.light) || shadeColor(primaryColor, 75);

export default {
  background: {
    default: "#f0f2f5",
  },

  text: {
    main: "#000",
    focus: "#7b809a",
  },

  transparent: {
    main: "transparent",
  },
  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#7e7e7e",
    focus: "#e91e63",
  },

  secondary: {
    main: secondaryColor,
    focus: secondaryColor,
  },

  info: {
    main: marathonColors.MARATHON.primary,
    focus: secondaryColor,
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: lightColor,
    focus: lightColor,
  },

  dark: {
    main: primaryColor,
    focus: primaryColor,
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: secondaryColor,
      state: secondaryColor,
    },

    secondary: {
      main: "#747b8a",
      state: "#495361",
    },

    info: {
      main: primaryColor,
      state: primaryColor,
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: lightColor,
      state: lightColor,
    },

    dark: {
      main: primaryColor,
      state: primaryColor,
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: {boxShadow: "#ddd"},
  },
};
