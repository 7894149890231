import React, {Component} from "react"
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@mui/material";
import Question from './Question'
import {v4} from 'uuid';

import Api from '../../../Api/Api'
import QRModal from '../../../oldComponents/QRModal'

import keys from '../../../config/keys'
import {BlobServiceClient} from '@azure/storage-blob'
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import {User} from "../../../context/user";
import userData from "../../../assets/data/user";


const blobSasUrl = keys.SAS_URL;
const blobService = new BlobServiceClient(blobSasUrl);
const containerName = 'videos';
const containerClient = blobService.getContainerClient(containerName);

const createBlobInContainer = async (containerClient, file) => {

  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = {blobHTTPHeaders: {blobContentType: file.type}};

  // upload file
  await blobClient.uploadBrowserData(file, options);

  const url = containerClient.getBlockBlobClient(file.name).url.split('?')[0]
  return url;
};

class QuizBuilder extends Component {
  static contextType = User;

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      cat: '',
      update: false,
      uploading: false,
      cats: [],
      emailLists: [],
      listsUsed: [],
      english: {
        title: '',
        data: [],
        video: '',
        thumb: '',
        file: null,
        thumbFile: null
      },
      spanish: {
        title: '',
        data: [],
        video: '',
        thumb: '',
        file: null,
        thumbFile: null
      },
      questions: [],
      groupCode: '',
      groupActive: false,
      groupUpdate: false,
      loadedQuiz: null,
      jobClassifications: [],
      quizFrequency: '',
      quizLocation: ''
    }
  }

  componentDidMount() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    const qid = urlParams.get('q')
    qid ? this.fetchQuiz(qid) : this.setId();

    Api.getAllELists().then(data => {
      this.setState({emailLists: data.data})
    })
    Api.getAllCats().then(data => {
      this.setState({cats: data.data})
    })
  }

  setId = () => {
    let id = v4();
    this.setState({id: id})
  }

  fetchQuiz(qid) {
    Api.getOneQuiz(qid).then(data => {
      let o = data.data[0]
      this.setState({
        id: o.id,
        cat: o.cat,
        update: true,
        english: o.english,
        spanish: o.spanish,
        questions: o.questions,
        listsUsed: o.listsUsed || [],
        groupCode: o.groupCode || '',
        groupActive: o.groupActive || false,
        loadedQuiz: data.data[0],
        jobClassifications: o.jobClassifications || [],
        quizFrequency: o.quizFrequency || '',
        quizLocation: o.quizLocation || ''
      })
      return true
    })
  }

  buildCategories() {
    let o = this.state.cats;
    let cats = [];
    o.forEach((c, i) => {
      !c.isDeleted && cats.push(<MenuItem key={i} value={c.id}>{c.title}</MenuItem>)
    })

    return cats;
  }

  buildELists(data) {
    console.log(data,"DATA")
    let lists = [];
    data.forEach((l, i) => {
      !l.isDeleted && lists.push(<MenuItem key={i} value={l.id}>{l.title}</MenuItem>)
    })

    return lists;
  }

  handleChange = (e, t, l) => {
    if (l) {
      let lang = this.state[l]
      lang[t] = t === 'file' ? e.target.files[0] : t === 'thumbFile' ? e.target.files[0] : e.target.value
      this.setState({[l]: lang})
    } else {
      let val;
      switch (t) {
        case 'file':
          val = e.target.files[0];
          break;
        case 'groupActive':
          val = e.target.checked
          break;
        default:
          val = e.target.value
      }
      this.setState({[t]: val})

    }
  }

  handleEListChange = (e) => {
    const {
      target: {value},
    } = e;

    this.setState({listsUsed: typeof value === 'string' ? value.split(',') : value})
  }

  newButton() {
    return (
      <>
        <Button variant="contained" onClick={this.addQuestion}>New Question</Button>
      </>
    )
  }

  addQuestion = () => {
    let questions = this.state.questions
    let id = questions.length;
    let question = {
      id: id,
      type: '',
      answer: null,
      english: {title: '', options: []},
      spanish: {title: '', options: []}
    }
    questions.push(question)

    this.setState({questions: questions})
  }

  delQuestion = (id) => {
    let questions = this.state.questions
    questions[id - 1].isDeleted = true
    this.setState({questions: questions})
  }

  renderQuestions() {
    let output = [];
    let questions = this.state.questions
    let count = 0;
    questions.forEach((q, i) => {
      !q.isDeleted && count++
      q && !q.isDeleted && output.push(<Question data={q} key={i} count={count} cb={this.saveData}
                                                 del={this.delQuestion}/>)
    })
    return (<Grid key={'yeet'} container spacing={2}>{output}</Grid>)
  }

  async handleFileUpload(file) {
    const blobClient = containerClient.getBlockBlobClient(file.name);

    // set mimetype as determined from browser with file upload control
    const options = {blobHTTPHeaders: {blobContentType: file.type}};

    // upload file
    await blobClient.uploadBrowserData(file, options);

    const url = containerClient.getBlockBlobClient(file.name).url.split('?')[0]

    return url;
  }

  saveData = (o) => {
    let q = this.state.questions
    q[o.id] = o;
    this.setState({questions: q})
  }

  saveButton = () => {

    const save = async () => {
      let upl = false;
      if (this.state.english.file || this.state.spanish.file || this.state.english.thumbFile || this.state.spanish.thumbFile) {
        upl = true
      } else {
        upl = false
      }
      upl && this.setState({uploading: true})
      const urlEnglish = this.state.english.file && await this.handleFileUpload(this.state.english.file);
      const urlSpanish = this.state.spanish.file && await this.handleFileUpload(this.state.spanish.file);
      const thumbEnglish = this.state.english.thumbFile && await this.handleFileUpload(this.state.english.thumbFile);
      const thumbSpanish = this.state.spanish.thumbFile && await this.handleFileUpload(this.state.spanish.thumbFile);
      let o = this.state
      let quiz = {
        id: o.id || '',
        cat: o.cat || '',
        questions: o.questions || [],
        english: {
          title: o.english.title || '',
          data: o.english.data || [],
          video: urlEnglish || o.english.video,
          thumb: thumbEnglish || o.english.thumb
        },
        spanish: {
          title: o.spanish.title || '',
          data: o.spanish.data || [],
          video: urlSpanish || o.spanish.video,
          thumb: thumbSpanish || o.spanish.thumb
        },
        listsUsed: o.listsUsed,
        groupCode: o.groupCode,
        groupActive: o.groupActive,
        jobClassifications: o.jobClassifications,
        quizFrequency: o.quizFrequency,
        quizLocation: o.quizLocation
      }

      this.state.update ? await Api.updateQuiz(JSON.stringify(quiz), quiz.id) : await Api.newQuiz(JSON.stringify(quiz))
      return true
    }

    const final = async () => {
      await save()
      window.location.href = '../'
    }

    return (
      <>
        <Button variant="contained" onClick={final}>SAVE QUIZ</Button>
      </>
    )
  }

  deleteButton = () => {
    const del = () => {
      if (window.confirm('Are you sure you want to delete this quiz? This action cannot be undone!')) {
        Api.delOneQuiz(this.state.id);
        window.location.href = '../'
      }
    }
    return (
      <>
        <Button variant="contained" onClick={del}>DELETE QUIZ</Button>
      </>
    )
  }

  async newVideo(data) {

    const file = data

    await createBlobInContainer(containerClient, file);

  }

  listLength = () => {
    let lists = this.state.listsUsed
    if (lists > 0) {
      return true
    } else {
      return false
    }
  }

  evalDisabled = () => {
    let dis = true
    if (this.state.groupCode) {
      if (this.state.groupActive) {
        dis = false
      }
    }
    return dis
  }

  hanldeGCDeactivate = (e) => {
    Api.updateGroupCode({id: this.state.loadedQuiz.id, value: {groupActive: e.target.checked}})
  }

  hanldeGC = (e) => {
    Api.updateGroupCode({id: this.state.loadedQuiz.id, value: {groupCode: e.target.value}})
  }

  render() {
    const user = this.context[0];

    const adminPlus = user.user.Admin === "Admin+";
    console.log(user, adminPlus, "USER");
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={0}>
          <Card style={{padding: 50, overflow: 'scroll'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{textAlign: 'center', marginBottom: 10}}>
                <h1>{!adminPlus ? "Quiz Editor" : "Quiz Builder"}</h1>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Grid container className="quiz-builder-container" spacing={2} alignItems="center">
                  {adminPlus && <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>Job Classification</InputLabel>
                        <Select
                          label="Job Classification"
                          multiple
                          value={this.state.jobClassifications}
                          onChange={e => this.setState({jobClassifications: e.target.value})}
                        >
                          {userData.classification.map((cls, i) => <MenuItem key={i} value={cls}>{cls}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>Location</InputLabel>
                        <Select
                          label="Location"
                          value={this.state.quizLocation}
                          onChange={e => this.setState({quizLocation: e.target.value})}
                        >
                          <MenuItem value="Website">Online</MenuItem>
                          <MenuItem value="OnSite">In Person</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>Frequency</InputLabel>
                        <Select
                          label="Frequency"
                          value={this.state.quizFrequency}
                          onChange={e => this.setState({quizFrequency: e.target.value})}
                        >
                          <MenuItem value="Annual">Annual</MenuItem>
                          <MenuItem value="TwoYears">2 years</MenuItem>
                          <MenuItem value="ThreeYears">3 years</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth color="secondary" label="Quiz Title" value={this.state.english.title}
                                 onChange={(e) => {
                                   this.handleChange(e, 'title', 'english')
                                 }}/>
                    </Grid>
                    <Grid item xs={2.9}>
                      <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        {this.state.cats && <Select
                          value={this.state.cat}
                          label="Category"
                          onChange={(e) => {
                            this.handleChange(e, 'cat')
                          }}
                        >
                          {this.buildCategories(this.state.cats)}
                        </Select>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.9}>
                      <FormControl fullWidth>
                        <InputLabel>Email Lists</InputLabel>
                        {(this.state.emailLists && this.state.emailLists.length > 0) && <Select
                          multiple
                          value={this.state.listsUsed || []}
                          label="Category"
                          onChange={(e) => {
                            this.handleEListChange(e, 'listsUsed')
                          }}
                        >
                          {this.buildELists(this.state.emailLists)}
                        </Select>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth color="secondary" label="Quiz Title (Spanish)"
                                 value={this.state.spanish.title} onChange={(e) => {
                        this.handleChange(e, 'title', 'spanish')
                      }}/>
                    </Grid>
                  </>}
                  <Grid item xs={!adminPlus ? 6 : 2.9} >
                    <TextField fullWidth color="secondary" label="Group Code" value={this.state.groupCode}
                               onChange={(e) => {
                                 this.handleChange(e, 'groupCode')
                               }} onBlur={this.hanldeGC}/>
                  </Grid>
                  <Grid item xs={!adminPlus ? 5 : 2.2}>
                    <FormGroup>
                      <FormControlLabel control={<Switch onChange={(e) => {
                        this.handleChange(e, 'groupActive')
                      }} checked={this.state.groupActive}/>} label="Group Code"
                                        onBlur={this.hanldeGCDeactivate}/>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={!adminPlus ? 1 : .4}>
                    <QRModal
                      gcid={this.state.groupCode}
                      active={this.evalDisabled()}
                    />
                  </Grid>
                  {!adminPlus && <Grid item xs={12} style={{marginTop: 20}}>
                    {this.saveButton()}
                  </Grid>}

                  {adminPlus && <>
                    <Grid item xs={2}>
                      {this.newButton()}
                    </Grid>
                    <Grid item xs={12}>&nbsp;</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={12}>
                      {this.renderQuestions()}
                    </Grid>
                    <div style={{
                      marginTop: 30,
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around'
                    }}>
                      <Grid item xs={5}>
                        <h3>Video Upload (English)</h3>
                        <h6>Current
                          video: {this.state.english.video && this.state.english.video.substring(this.state.english.video.lastIndexOf('/') + 1)}</h6>
                        <input type="file" onChange={(e) => this.handleChange(e, 'file', 'english')}/>
                      </Grid>
                      <Grid item xs={5}>
                        <h3>Thumbnail (English)</h3>
                        <h6>Current
                          thumbnail: {this.state.english.thumb && this.state.english.thumb.substring(this.state.english.thumb.lastIndexOf('/') + 1)}</h6>
                        <input type="file" onChange={(e) => this.handleChange(e, 'thumbFile', 'english')}/>
                      </Grid>
                      <Grid item xs={5}>
                        <h3>Video Upload (Spanish)</h3>
                        <h6>Current
                          video: {this.state.spanish.video && this.state.spanish.video.substring(this.state.spanish.video.lastIndexOf('/') + 1)}</h6>
                        <input type="file" onChange={(e) => this.handleChange(e, 'file', 'spanish')}/>
                      </Grid>
                      <Grid item xs={5}>
                        <h3>Thumbnail (Spanish)</h3>
                        <h6>Current
                          thumbnail: {this.state.spanish.thumb && this.state.spanish.thumb.substring(this.state.spanish.thumb.lastIndexOf('/') + 1)}</h6>
                        <input type="file" onChange={(e) => this.handleChange(e, 'thumbFile', 'spanish')}/>
                      </Grid>
                    </div>
                    <Grid item xs={12}>
                      {this.state.uploading && <>File(s) uploading. Page will return to admin panel when
                        complete.</>}
                    </Grid>
                    <Grid item xs={6}>
                      {this.saveButton()}
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right', paddingRight: '20px'}}>
                      {this.deleteButton()}
                    </Grid>
                  </>}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </MDBox>
      </DashboardLayout>
    )
  }
}

export default QuizBuilder;
