import React, {Component} from 'react';
// import { render } from "@testing-library/react";
// import { v4 as uuidv4 } from 'uuid';
// import Api from '../Api/Api'


class LongAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: null
    }
  }


  render() {
    return (
      <>
        LongAnswer
      </>
    )
  }

}

export default LongAnswer
