/* eslint-disable no-unused-vars */
/**
 All of the routes for the Material React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material React layouts
// import Users from "layouts/dashboards/users";
// import Settings from "layouts/dashboards/settings";
import Videos from "layouts/views/videos";
import Admin from "layouts/views/admin";
import SafetyDocuments from "layouts/views/safetyDocuments";
import Profile from "layouts/views/profile";
// import AuthPage from "layouts/auth"
// @mui icons
import Icon from "@mui/material/Icon";
import Auth from "./layouts/auth";
import QuizBuilder from "./layouts/views/admin/QuizBuilder";
import QuizPage from "./oldComponents/QuizPage";


const routes = [

  // Main Routes
  {
    type: "collapse",
    name: "Home",
    nameEsp: "Casa",
    key: "home",
    route: "/videos",
    icon: <Icon fontSize="medium">videocam</Icon>,
    component: <Videos/>,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: "Profile",
    nameEsp: "Perfil",
    key: "profile",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    route: "/profile",
    component: <Profile/>,
    noCollapse: true,
  },
    {
      type: 'collapse',
      name: "Safety Documents",
      nameEsp: "Documentos de Seguridad",
      key: "safety_document",
      icon: <Icon fontSize="medium">description</Icon>,
      route: "/safety-documents",
      component: <SafetyDocuments/>,
      noCollapse: true,
    },
  {
    name: "Auth",
    key: "auth",
    route: "/auth",
    component: <Auth/>,
    noCollapse: true,
  },
  {
    name: "Quiz Builder",
    key: "quizbuilder",
    route: "/admin/quizbuilder",
    component: <QuizBuilder/>,
    noCollapse: true,
  },
  {
    name: "Quiz",
    key: "quiz",
    route: "/quiz",
    component: <QuizPage/>,
    noCollapse: true,
  },


  // Admin
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    route: "/admin",
    user: 'Admin',
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    component: <Admin/>,
    noCollapse: true,
  },

  // Videos
  // {
  //   // type: "collapse",
  //   name: "Safety Orientation",
  //   key: "safetyorientation",
  //   route: "/videos/safetyorientation",
  //   component: <CardView />,
  //   video: true,
  //   noCollapse: true,
  //   meta: {
  //     "_id": "628fd09008983856628997a6",
  //     "id": "9a1d621e-f4ca-4bb5-a65f-7ef03a4430ff",
  //     "title": "Safety Orientation",
  //     "spanish": "Nueva Orientación de Alquiler",
  //     "isDeleted": false,
  //     "password": "",
  //     link: '/videos/safetyorientation'
  //   }
  // },
  // {
  //   // type: "collapse",
  //   name: "Initial Safety Training (Every 3 Years)",
  //   key: "initialsafetytraining(every3years)",
  //   route: "/videos/initialsafetytraining(every3years)",
  //   component: <CardView />,
  //   video: true,
  //   noCollapse: true,
  //   meta: {
  //     "_id": "628fd0e308983856628997a7",
  //     "id": "4f7c05e3-4f50-4399-865a-a0edc92f134b",
  //     "title": "Initial Safety Training (Every 3 Years)",
  //     "spanish": "Formación inicial en seguridad cada 3 años",
  //     "isDeleted": false,
  //     link: '/videos/initialsafetytraining(every3years)'
  //   }
  // },
  // {
  //   // type: "collapse",
  //   name: "Annual Refresher Training",
  //   key: "annualrefreshertraining",
  //   route: "/videos/annualrefreshertraining",
  //   component: <CardView />,
  //   video: true,
  //   noCollapse: true,
  //   meta: {
  //     "_id": "628fd12508983856628997a8",
  //     "id": "9a1d621e-f4ca-4bb5-a65f-7ef03a4430fe",
  //     "title": "Annual Refresher Training",
  //     "spanish": " Capacitación de Actualización Anual",
  //     link: '/videos/annualrefreshertraining'
  //   }
  // },
  // {
  //   // type: "collapse",
  //   name: "Master Electrician CEU Training",
  //   key: "masterelectricianceutraining",
  //   route: "/videos/masterelectricianceutraining",
  //   component: <CardView />,
  //   video: true,
  //   noCollapse: true,
  //   meta: {
  //     "_id": "62c83eb57ea3a9f7b13de9db",
  //     "id": "bb27c05f-86e4-48ad-8b87-b734bab53538",
  //     "title": "Master Electrician CEU Training",
  //     "isDeleted": false,
  //     "password": "Formación Master Electricista CEU",
  //     "spanish": "Entrenamiento ",
  //     link: '/videos/masterelectricianceutraining'
  //   }
  // },
  // {
  //   // type: "collapse",
  //   name: "Equipment Training",
  //   key: "equipmenttraining",
  //   route: "/videos/equipmenttraining",
  //   component: <CardView />,
  //   video: true,
  //   noCollapse: true,
  //   meta: {
  //     "_id": "62d004d07ea3a9f7b13de9e7",
  //     "id": "f9eab94e-9e31-4d67-90cc-5ed40d2453f3",
  //     "title": "Equipment Training",
  //     "spanish": "Entrenamiento de Equipos ",
  //     "isDeleted": false,
  //     "password": "Operator",
  //     link: '/videos/equipmenttraining'
  //   }
  // },
  // {
  //   // type: "collapse",
  //   name: "EM385 Module Training",
  //   key: "em385moduletraining",
  //   route: "/videos/em385moduletraining",
  //   video: true,
  //   component: <CardView />,
  //   noCollapse: true,
  //   meta: {
  //     "_id": "6357d8c5b15b47d76d7b30f8",
  //     "id": "26cf6bfe-f578-4a32-b01d-b53236b0e153",
  //     "title": "EM385 Module Training",
  //     "spanish": "Entrenamiento de Modulo EM385",
  //     "isDeleted": false,
  //     "password": "EM385",
  //     link: '/videos/em385moduletraining'
  //   }
  // },
  // {
  //   // type: "collapse",
  //   name: "First Aid - CPR - AED",
  //   key: "firstaid-cpt-aed",
  //   video: true,
  //   route: "/videos/firstaid-cpr-aed",
  //   // icon: <Icon fontSize="medium">bar_chart</Icon>,
  //   component: <CardView />,
  //   noCollapse: true,
  //   meta: {
  //     "_id": "63c95bb3a23835a238a8e85d",
  //     "id": "61338f62-e126-46d1-aaea-a2128c72d06f",
  //     "title": "2023 Monthly Safety Discussion",
  //     "spanish": "Discusión mensual de seguridad 2023",
  //     "isDeleted": true,
  //     "password": "Safety Meeting",
  //     link: '/videos/firstaid-cpt-aed'
  //   }
  // }
];

export default routes;
