import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import Api from '../Api/Api';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import {qr} from '../constants';
import {resetActiveQuiz, useUserController} from "../context/user";
import {useMaterialUIController} from "../context";
import language from "../assets/data/language";
import * as htmlToImage from 'html-to-image';
import azure from "../utils/azure"
import emailjs from "emailjs-com";

const cookies = new Cookies();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  maxWidth: '98%',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  li: {
    listStyleType: 'decimal'
  },
  h2: {
    textAlign: 'center'
  }
};

const RenderCert = ({data, spanish, template, score}) => {
  const [generating, setGenerating] = React.useState(true)

  const certUrl = React.useRef(false);
  const domEl = React.useRef(null);
  const certDone = React.useRef(false);
  // const link = document.createElement('a');

  const createSaveCert = async () => {
    const savedQuiz = await Api.createCert(data);
    let quiz = savedQuiz?.data?.quiz
    // eslint-disable-next-line
    const url = await htmlToImage.toBlob(document.getElementById('domEl'), {quality: 0.95, skipFonts: true})
      .then(function (dataUrl) {
        return azure.uploadCertNew(dataUrl, `${data.userID || data.emp_email}/${data.cert_name}.png`, data, quiz)
      })
    data.certificate_url = url;
    let emailData = {
      service_id: 'service_27j4zif',
      template_id: "prod_template",
      user_id: 'uLBRMNt_Pnb3kQlS7',
      template_params: data
    };

    await emailjs.send(emailData.service_id, emailData.template_id, emailData.template_params, emailData.user_id)
      .then((result) => {
        console.log(result, "RED")
        certDone.current = true
      }, (error) => {
        console.log(error, false)
        return error
      });
    return url;
  }
  if(!certDone.current) createSaveCert().then(url => {
    certUrl.current = url;
    console.log(url, "URL")
    setGenerating(false)
    // send email
  }).catch(err => {
    console.log(err, "FAILED TO CRAETE CERT?")
    setGenerating(false)
  })

  const downloadImage = async () => {
    htmlToImage.toPng(document.getElementById('domEl'), { quality: 0.95, skipFonts: true })
      .then(function (dataUrl) {
        // azure.uploadCertNew(dataUrl,  `${data.userId || data.emp_email}/${data.cert_name}.jpeg`, data)
        let link = document.createElement('a');
        link.download = `${data.cert_name}.png`;
        link.href = dataUrl;
        link.click();
      });
  }
  return <div>
    <p style={{textAlign: 'center', marginTop: 10, fontWeight: '600'}}>
      {(score >= 70) && <>
        {generating ? "Sending email..." : "A copy of your results has been emailed to you and to the training department to be kept on record in your file. "}
      </>}
      {/*{!generating && <Button*/}
      {/*  variant="contained"*/}
      {/*  onClick={createSaveCert}*/}
      {/*  style={{*/}
      {/*    marginBottom: 10*/}
      {/*  }}*/}
      {/*> Resend email</Button>}*/}
    </p>
    <div style={{
      overflow: "hidden",
      backgroundColor: 'black',
      maxHeight: 200,
      maxWidth: 600,
      position: 'relative',
      border: '3px solid #d94d41',
      marginBottom: 10,
    }}>
      <Button
        onClick={downloadImage}
        variant="contained"
        style={{
          position: 'absolute',
          top: "50%",
          zIndex: 99,
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >{language.common.download(spanish)}</Button>
      <div style={{opacity: .5}}>
        <div style={{width: 806, height: 600}} id="domEl" ref={domEl}>
          <div
            style={{
              width: 806,
              height: 600,
              backgroundImage: "url('https://trainingvideo10.blob.core.windows.net/cdn/Blank_Certificate.jpg')",
              backgroundSize: "cover"
            }}>
            <h3
              style={{
                margin: 0,
                zIndex: 1,
                position: "relative",
                top: 140,
                fontSize: 30,
                textAlign: "center"
              }}>{data.emp_name}</h3>
            <h3
              style={{
                margin: 0,
                zIndex: 1,
                position: "relative",
                top: 210,
                fontSize: 30,
                textAlign: "center"
              }}>{data.certification_title}</h3>
            <p
              style={{
                margin: 0,
                zIndex: 1,
                position: "relative",
                top: 277,
                fontSize: 15,
                textAlign: "center",
                right: -150
              }}>{data.todays_date}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

}
RenderCert.propTypes = {
  data: PropTypes.any,
  spanish: PropTypes.bool,
  template: PropTypes.string,
  // setGenerating: PropTypes.func,
  score: PropTypes.any,
}


export default function ResultsModal(props) {

  let cb = props.cb;


  const [controller] = useMaterialUIController();
  const {spanish} = controller;
  const [userController, userDispatch] = useUserController();
  const {user, activeQuiz} = userController;
  //for dev testing
  const handsOn = props.data.cat === 'f9eab94e-9e31-4d67-90cc-5ed40d2453f3' ? true : false
  const template = props.data.id === "9e4633aa-9ff9-4fcf-a9eb-88677f9035eb" ? 'prod_template' : 'prod_template'

  let navigate = useNavigate();
  user.currentVideo = ''

  const [renderCert, setRenderCert] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [cat, setCat] = React.useState('')
  // const [generating, setGenerating] = React.useState(false)
  const [allELists, setAllELists] = React.useState([])
  const [eList, setEList] = React.useState([])

  React.useEffect(() => {
    Api.getOneQuiz(props.data.id).then(res => {
      setEList(res.data[0].listsUsed)
      return res.data[0].cat
    }).then(c => {
      Api.getOneCat(activeQuiz?.category || cat).then(res => {
        // console.log(res, 'RES')
        setCat(res.data[0].title)
      })
    })

    // Api.getAllELists().then(data => {
    //   setAllELists(data.data)
    //   return true
    // }).then(res => {
    // })
  }, [])

  // toggle on if quiz fail, no cert
  // return to beginning of video
  const handleOpen = async () => {
    const score = await cb();
    // console.log(score, "SCORE")
    setOpened(true);
    if (score >= 70) {
      // setGenerating(true);
      await didPass(score);
    }
  };

  const didPass = async (score) => {
    // user.updateVData(null)
    // console.log(activeQuiz, props, "PROPS")
    // user.updateCrwUrl(null)
    await sendEmail(score);
  }

  const handleClose = () => {
    setOpened(false);
    resetActiveQuiz(userDispatch)
    goHome();
  };

  const getSpecifiedEmails = async () => {
    const emails = []

    let emailString = ''

    allELists.forEach((el, i) => {
      eList.forEach((l, j) => {
        if (el.id === l) {

          el.emails.forEach(em => {
            !el.isDeleted && !em.isDeleted && emails.push(em.email)
          })
        }
      })
    })

    emailString = emails.join(",")

    return emailString;
  }

  const sendEmail = async (score) => {

    const emails = eList && await getSpecifiedEmails();

    const qrCode = handsOn ? qr : null
    const qrUrl = handsOn ? 'Hands On Training Form: https://forms.office.com/Pages/ResponsePage.aspx?id=GXqmInPrOkqJkwQaKPAWYMsuunUB6UBFv8qDuDebq_RURExQQzlYSUdKWkJaTFhLSTUyREM4MTE1QS4u&embed=true' : ''

    let title = props.data.english.title;

    //hotfix for missing cookie update
    let name = 'contact developer';
    name = user.FirstName + ' ' + user.LastName;
    const userCookie = cookies.get('user')

    let now = new Date();
    let dd = String(now.getDate()).padStart(2, '0');
    let mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = now.getFullYear();

    let h = now.getHours();
    let m = now.getMinutes();
    let s = now.getSeconds();

    let today = mm + '/' + dd + '/' + yyyy;
    const today_str = mm + dd + yyyy + '' + h + '' + m + '' + s

    let params = {
      emp_name: name,
      emp_email: user.email,
      to_email: user.email,
      jobClassification: user.JobClassification,
      userID: userCookie?.userId || activeQuiz?.userId || user?.id,
      category: cat,
      metadata: activeQuiz,
      certification_title: title,
      quiz_score: score,
      todays_date: today || "",
      cert_name: title + '-' + today_str,
      cert_url: '',
      specified_emails: emails ? emails + ',' : '',
      // ^ for item 4
      handsonqr: qrCode,
      handsonurl: qrUrl
    }
    // const certUrl = await createCert.js(params)
    // const certUrl = await createCertFrontEnd(params)
    setRenderCert(params)
    // params.cert_url = certUrl
    // //
    // let data = {
    //   service_id: 'service_leemyzb',
    //   template_id: template,
    //   user_id: 'OEpIsDjbJHxouxTll',
    //   template_params: params
    // };
    //
    // emailjs.send(data.service_id, data.template_id, data.template_params, data.user_id)
    //   .then((result) => {
    //     console.log(result, "RED")
    //     setGenerating(false)
    //   }, (error) => {
    //     console.log(false)
    //     setGenerating(false)
    //   });

  }

  const createCert = async (params) => {
    // console.log(params.name, params.emp_name);
    let res = await Api.createCert(params)

    return res.data
  }

  const goHome = () => {
    navigate(-1)
  }

  const resultsResponse = () => {
    let pass = "You have passed this evaluation.";
    let fail = "The minimum score to pass this evaluation is 70. You will need to retake the quiz. Click to return to the main page, re-watch the training video, and try again."
    console.log('LOG TEST 12/29')

    return props.score >= 70 ? pass : fail
  }


  return (
    <div>
      <Button variant="contained" onClick={handleOpen} disabled={props.dis}>{props.spanish ? "Enviar" : "Submit"}</Button>
      <Modal
        open={opened}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        color="primary"
      >
        <Box sx={{
          ...style,
          width: 600,
          minHeight: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center'
        }}>
          {(!renderCert) ?
            <>
            {props.score >= 70 ?
              <>
                <h2 id="parent-modal-title">EVALUATING...</h2>
                <p>{spanish ? 'Cuestionario de evaluación. No abandone esta página hasta que los resultados estén listos o deberá volver a realizar el cuestionario.' : 'Evaluating quiz. Please do not leave this page until results are ready or quiz will need to be retaken.'}</p>
              </> : <p style={{textAlign: 'center'}}>
                {resultsResponse()}
              </p>}
              </>
              :
              <>
            {renderCert && <RenderCert data={renderCert} spanish={spanish} template={template} score={props.score}/>}
          <h2 id="parent-modal-title">RESULTS</h2>
          <p style={{textAlign: 'center'}}>
                You have completed this quiz with a score of {props.score}.
              </p>
              <Button style={{marginTop: 20}} variant="contained" onClick={handleClose}>Exit</Button>
            </>
          }
        </Box>
      </Modal>
    </div>
  );
}

ResultsModal.propTypes = {
  data: PropTypes.object,
  user: PropTypes.object,
  cb: PropTypes.any,
  userId: PropTypes.any,
  spanish: PropTypes.bool,
  dis: PropTypes.bool,
  score: PropTypes.any,


}
