import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import QRCode from 'react-qr-code';
import PropTypes from "prop-types";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxWidth: '98%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 1,
  px: 1,
  pb: 1,
  li: {
    listStyleType: 'decimal'
  },
  h2: {
    textAlign: 'center'
  }
};

export default function NestedModal(props) {

  var gcid = props.gcid

  const navigate = useNavigate();

  const [opened, setOpened] = React.useState(false);
  const [domain, setDomain] = React.useState('')

  React.useEffect(() => {
    var url = window.location.host
    setDomain(url)
  }, [])

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} disabled={props.active} style={{
        margin: 0,
        padding: 0,
        maxWidth: '40px',
        minWidth: '0',
        width: '40px',
        height: '36px'
      }}>QR</Button>
      <Modal
        open={opened}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        color="primary"
      >
        <Box sx={{...style, width: 800}}>
          <h2 id="parent-modal-title">SCAN TO GO TO QUIZ</h2>
          <div style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer',
            fontWeight: 800,
            color: '#EB1F23',
            border: '1px solid #EB1F23',
            borderRadius: '4px',
            padding: '0px 5px'
          }} onClick={() => {
            handleClose()
          }}>CLOSE
          </div>
          <span id="parent-modal-description"
                style={{textAlign: 'center', padding: '32px', width: '100%', display: 'inline-block'}}>
            <QRCode
              size={256}
              style={{height: "auto", maxWidth: "50%", width: "50%"}}
              value={domain + '/quiz/?gcid=' + gcid}
              viewBox={`0 0 128 128`}
              onClick={() => {
                navigate('/quiz/?gcid=' + gcid)
              }}
            />
            <h3 style={{marginBottom: 0, marginBlockEnd: 0}}>Group Code:</h3>
            <h2 style={{marginTop: 0, marginBlockStart: 0}}>{gcid}</h2>
            <h3 style={{marginBottom: 0, marginBlockEnd: 0}}>URL:</h3>
            <h3 style={{marginTop: 0, marginBlockStart: 0}}>{domain + '/quiz/?gcid=' + gcid}</h3>
          </span>
        </Box>
      </Modal>
    </div>
  );
}

NestedModal.propTypes = {
  gcid: PropTypes.any,
  active: PropTypes.any,
};
