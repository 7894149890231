import React, {Component} from 'react';
import {Radio, RadioGroup} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from "prop-types";

class MultipleChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: []
    }
  }

  componentDidMount() {
    let ak = []
    this.props.q.options.forEach(o => {
      ak.push(false)
    })
    this.setState({answers: ak})
  }

  showOptions(o) {
    let opts = [];
    o.forEach((opt) => {
      !opt.isDeleted && opts.push(<FormControlLabel key={opt.id} value={opt.id} control={<Radio/>}
                                                    label={opt.title.replace(/^\w/, (c) => c.toUpperCase())}/>)
    })
    return opts;
  }

  handleChange = (e) => {
    let ans = this.state.answers;
    this.setState({answers: ans})
    this.props.cb({q: this.props.count, a: e.target.value})
  }

  render() {
    let q = this.props.q;
    return (
      <li key={this.props.qid}>
        <h3>
          {q.title}
        </h3>
        <FormControl onChange={this.handleChange}>
          <RadioGroup onChange={this.handleChange}>
            {this.showOptions(q.options)}
          </RadioGroup>
        </FormControl>
      </li>
    )
  }
}

MultipleChoice.propTypes = {
  q: PropTypes.any,
  cb: PropTypes.any,
  count: PropTypes.any,
  qid: PropTypes.any
}

export default MultipleChoice
