import React, {Component} from "react"
import {Button, Grid, Typography} from "@mui/material";
import SingleEList from "./SingleEList";
import {v4} from 'uuid';
import Api from '../../../Api/Api';

class EmailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: []
    }
  }

  componentDidMount() {
    this.fetchELists();
  }

  fetchELists = () => {
    Api.getAllELists().then(data => {
      this.setState({lists: data.data});
    })
  }

  displayELists() {
    var lists = this.state.lists;
    var output = []

    lists.forEach((l, i) => {
      if (l.isDeleted !== true) {
        output.push(
          <SingleEList key={i} data={l} new={l.title ? false : true} del={() => {
            this.delList(l.id)
          }}/>
        )
      }

    })
    return output;
  }

  newList = () => {
    var lists = this.state.lists;
    var lid = v4();
    lists.push({id: lid, title: '', emails: [], isDeleted: false})
    this.setState({lists: lists})
  }

  delList = (id) => {
    var lists = this.state.lists
    lists.forEach(l => {
      l.isDeleted = l.id === id && true
    })
    this.setState({lists: lists})
  }

  render() {
    return (
      <>
        <Grid container style={{marginBottom: '10px'}}>
          <Grid item xs={6} display="flex" alignItems="center">
            <Typography variant="h5" style={{padding: '12px 0'}}>Email Lists</Typography>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right', justifyContent: 'right'}} display="flex"
                alignItems="center">
            <Button variant="outlined" onClick={this.newList}>New List</Button>
          </Grid>
        </Grid>
        <Grid container
              style={{marginTop: '10px', borderTop: '1px solid #ccc', maxHeight: "400px", overflow: "scroll"}}>
          <Grid item xs={12}>
            {this.displayELists()}
          </Grid>
        </Grid>
      </>
    )
  }
}

export default EmailList;
