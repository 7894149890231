/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import MDSnackbar from "components/MDSnackbar";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: "",
      show: true,
    };
    this.toggleSnackbar = this.toggleSnackbar.bind(this);
  }

  //   static getDerivedStateFromError(error) {
  //     // Update state so the next render will show the fallback UI.
  //     return { hasError: true };
  //   }

  componentDidCatch(error, info) {
    console.log("---> ErrorBoundary", error, info);
    this.setState({error: error.toString(), hasError: true});
    // trackError(
    //   error.toString(),
    //   {
    //     error: error.toString(),
    //     location: "errorBoundary",
    //     errorInfo: info,
    //   },
    //   this.props.user
    // );
    setTimeout(() => {
      window.location.href = "/";
    }, 6000);
  }

  toggleSnackbar() {
    this.setState({show: !this.state.show});
  }

  render() {
    if (this.state.hasError) {
      return (
        <MDSnackbar
          color="error"
          icon="warning"
          title="OOPS SOMETHING WENT WRONG"
          content={this.state.error}
          dateTime=""
          open={this.state.show}
          close={this.toggleSnackbar}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
